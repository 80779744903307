
export const PropertyTypes = [
    { id: "apartment", name: "Ejerlejlighed" },
    { id: "house", name: "Villa" },
    { id: "renovation", name: "Håndværkertilbud" },
    { id: "townhouse", name: "Rækkehus" },
    { id: "plot", name: "Grund" },
    { id: "co-appartment", name: "Andelslejlighed" },
    { id: "cottage", name: "Sommerhus" },
]

export const SpecialWishes = [
    { id: "pool", name: "Pool", icon: "pool" }, 
    { id: "slope-wall", name: "Skråvægge", icon: "format_shapes" },
    { id: "architecture", name: "Arkitekttegnet", icon: "architecture" },
    { id: "fireplace", name: "Brændeovn", icon: "fireplace" },
    { id: "garage", name: "Garage", icon: "garage" },
    { id: "carport", name: "Carport", icon: "directions_car" },
    { id: "hobby-room", name: "Hobbyrum", icon: "brush" },
    { id: "move-in-ready", name: "Indflytningsklar", icon: "check_circle" },
    { id: "basement", name: "Kælder", icon: "foundation" },
    { id: "terrace", name: "Terrasse", icon: "deck" },
    { id: "kitchen-living-room", name: "Køkkenalrum", icon: "kitchen" },
    { id: "balcony", name: "Altan", icon: "balcony" },
    { id: "elevator", name: "Elevator", icon: "elevator" },
    { id: "handicap-friendly", name: "Handicapvenlig", icon: "accessible" },
    { id: "no-address-required", name: "Uden bopælspligt", icon: "public" },
]

export const AreaWishes = [
    { id: "green-areas", name: "Grønne områder", icon: "park" },
    { id: "public-school", name: "Folkeskole", icon: "school" },
    { id: "shopping-options", name: "Indkøbsmuligheder", icon: "shopping_cart" },
    { id: "beach", name: "Strand", icon: "beach_access" },
    { id: "sports-facilities", name: "Sportsfaciliteter", icon: "sports_soccer" },
    { id: "public-transport", name: "Offentlig transport", icon: "directions_bus" },
    { id: "city-center", name: "Central lokation", icon: "location_city" },

]


export const translatePropertyTypes = (key?: string) => {
    if (!key) return ""
    const item = PropertyTypes.find((i) => i.id === key)
    if (item) return item.name
    return key
}
