import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import hooks from '../utils/hooks'
import { Spinner, SpinnerSize, Stack } from '@fluentui/react'
import { AppBody } from '../components/wrappers'


const EditCompanyProductPage: React.FC = () => {
    const navigate = useNavigate()
    const [url, setUrl] = React.useState<string>()

    React.useEffect(() => {
        (async () => {
            try {
                const result = await hooks.getBillingSessionURL()
                if(result) {
                    setUrl(result)
                    //eslint-disable-next-line
                    window.location.href = result
                }else navigate('/error')
            } catch (error) {
                navigate('/error')
            }
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <AppBody>
            <Stack horizontal>
                <Stack>
                    <Stack.Item><h2>Vi viderstiller dig til faktureringsportalen</h2></Stack.Item>
                    <Stack.Item>{!url ?<Spinner size={SpinnerSize.medium} style={{ height: 32 }} />: <span>Viderstilles du ikke automatisk, så <a href={url}>tryk her</a></span>}</Stack.Item>
                </Stack>
            </Stack>
        </AppBody>
    )
}

export default EditCompanyProductPage