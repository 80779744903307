import { DefaultButton, Link, PrimaryButton, Spinner, SpinnerSize } from '@fluentui/react'
import * as React from 'react'
import { LargeGap } from '../utils/globals'


export enum ButtonTypes {
    primary, 
    secondary,
    text
}
export const ButtonWithLoading: React.FC<{ onClick(): Promise<void>, disabled?: boolean, text: string, buttonType?: ButtonTypes }> = ({ onClick, disabled, text, buttonType }) => {
    const [loading, setLoading] = React.useState(false)
    const handleClick = async () => {
        if(disabled) return
        setLoading(true)
        await onClick()
        setLoading(false)
    }

    if (loading) return <Spinner size={SpinnerSize.medium} style={{ height: 32 }} />

    if (buttonType === ButtonTypes.text) return <span onClick={handleClick} style={{ textDecoration: disabled ? 'none' : 'underline', cursor: 'pointer' }}>{text}</span>
    if(buttonType === ButtonTypes.secondary) return <DefaultButton text={text} onClick={handleClick} allowDisabledFocus disabled={disabled} />
    return <PrimaryButton text={text} onClick={handleClick} allowDisabledFocus disabled={disabled} />
}


export const SignOutButton: React.FC<{ noMargin?: boolean, customText?: string }> = ({ noMargin, customText }) => {
    return (<div style={{ marginTop: noMargin ? 0 : LargeGap }}>
        <Link href='/logout'>{customText ? customText : "Log ud"}</Link>
    </div>)
}