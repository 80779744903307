import * as React from 'react'
import hooks from '../utils/hooks'
import { useNavigate } from 'react-router-dom'
import { Stack, StackItem } from '@fluentui/react'
import { LoginBody } from '../components/wrappers'
import { SmallGap } from '../utils/globals'
import { ErrorContext } from '../components/wrappers';
import { InputWithLabel, validateEmail } from '../components/Fields'
import { ButtonWithLoading } from '../components/Button'


const Login: React.FC = () => {
    let navigate = useNavigate()
    const { showErrorMessage } = React.useContext(ErrorContext)
    const [email, setEmail] = React.useState<string>()

    const handleSubmissionEvent = async () => {
        if (!email) return
        const result = await hooks.startLogin({ email, agentSignIn: true })
        if (result) {
            navigate('/confirmlogin/' + result.loginChallengeId)
        }else {
            showErrorMessage("Der skete en fejl")
        }
    }

    React.useEffect(() => {
        (async () => {
            const result = await hooks.testLogin()
            if (result && result.agentId && !result.agencyId) navigate('/business-signup')
            else if(result && result.agentId) navigate('/')
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const invalid = !validateEmail(email)

    return (
        <LoginBody>
            <Stack tokens={{ childrenGap: SmallGap }}>
                <StackItem><h1>Login</h1></StackItem>

                <InputWithLabel setValue={setEmail} value={email} label='Email' />

                <StackItem> <ButtonWithLoading text="Næste" onClick={handleSubmissionEvent} disabled={invalid || !email} /></StackItem>
                <StackItem><a href='/user-signup'>Opret profil</a></StackItem>
            </Stack>
        </LoginBody>
    )
}

export default Login