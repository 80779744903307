import * as React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import hooks from '../utils/hooks'
import { DefaultButton, Panel, PanelType, Persona, PersonaSize, Pivot, PivotItem, SelectionMode, ShimmeredDetailsList, Stack } from '@fluentui/react'
import { AppBody } from '../components/wrappers'
import { Agency, Agent, AnonymusLead, SalesLead, User } from '@venando/common'
import { LargeGap, SmallGap } from '../utils/globals'
import { DateStringToNiceDate } from '../utils/formatters'
import { ButtonWithLoading } from '../components/Button'
import zipcodeLib from '../utils/zipcode-lib';
import Tags from '../components/Tags'

const RootPanel: React.FC<{ children: React.ReactNode, user?: User }> = ({ children, user }) => {
    const [open, setOpen] = React.useState(false)

    let [searchParams] = useSearchParams()
    const queryParameters = new URLSearchParams(searchParams);
    const preselectedUserId = queryParameters.get('userId')

    React.useEffect(() => {
        if (preselectedUserId && user && preselectedUserId === user?.userId) setOpen(true)
        //eslint-disable-next-line
    }, [preselectedUserId])

    const enterView = () => setOpen(true)

    const exitView = () => setOpen(false)


    return (
        <>
            <DefaultButton text="Se mere" onClick={enterView} />
            <Panel isOpen={open} onDismiss={exitView} isLightDismiss
                type={PanelType.medium} closeButtonAriaLabel="Close">
                {children}
            </Panel>
        </>
    )
}

const SellerPanelView: React.FC<{ user: User }> = ({ user }) => {

    return (
        <RootPanel user={user}>
            <Persona text={user.name} size={PersonaSize.size72} secondaryText={user.email} tertiaryText={user.phone} />
            <Pivot style={{ marginTop: LargeGap }}>
                {user.existingProperties.map((item, key) => (
                    <PivotItem key={key} headerText={item.address} itemKey={item.address} style={{ paddingTop: SmallGap }}>
                    </PivotItem>
                ))}
            </Pivot>
        </RootPanel>
    )
}

const NewSellerPanelView: React.FC<{ userId: string, areaId: string }> = ({ userId, areaId }) => {

    const handleBuy = async () => {
        const result = await hooks.startSellerPurchase(userId)
        if (result) hooks.navigate(result)
    }

    return (
        <RootPanel>
            <Stack.Item>
                <div style={{ filter: 'blur(5px)', paddingTop: SmallGap }}>
                    <Persona text="Anonymisere navn" size={PersonaSize.size72} secondaryText={"anonymisere@mail.com"} tertiaryText="Anonymisere telefon" />
                </div>
            </Stack.Item>
            <Pivot style={{ marginTop: LargeGap }}>
                <PivotItem headerText={zipcodeLib.getZipDisplayName(areaId)} itemKey={zipcodeLib.getZipDisplayName(areaId)} style={{ paddingTop: SmallGap }}>
                    <Stack horizontalAlign='center' tokens={{ childrenGap: LargeGap }} style={{ marginTop: LargeGap }}>
                        <Stack.Item><h3 style={{ margin: 0, padding: 0 }}>Få adgang til dette lead</h3></Stack.Item>
                        <Stack.Item><ButtonWithLoading onClick={handleBuy} text={'Køb dette lead'} /></Stack.Item>
                        <Stack.Item><b>Pris</b> 400.00 DKK eks moms</Stack.Item>
                    </Stack>
                </PivotItem>
            </Pivot>
        </RootPanel>
    )
}


const defaultColumns = [
    { key: 'adresse', name: 'Adresse', fieldName: 'address', minWidth: 100, isResizable: true },
    { key: 'created', name: 'Oprettet', fieldName: 'created', minWidth: 100, maxWidth: 120, isResizable: true },
    { key: 'details', name: 'Se mere', fieldName: 'details', minWidth: 110, maxWidth: 140, isResizable: true },
]


const SellersPage: React.FC = () => {
    const [loading, setLoading] = React.useState(true)
    const [, setAgent] = React.useState<Agent>()
    const [agency, setAgency] = React.useState<Agency>()
    const [purchasedSalesLeads, setPurchasedSalesLeads] = React.useState<SalesLead[]>()
    const [salesLeads, setSalesLeads] = React.useState<AnonymusLead[]>()

    let [searchParams] = useSearchParams()
    const queryParameters = new URLSearchParams(searchParams);
    const leadId = queryParameters.get('leadId')
    console.log("searchParams")


    React.useEffect(() => {
        (async () => {
            if (leadId) {
                const success = await hooks.completeSellerPurchase(leadId)
                if (success) hooks.navigate('/seller-leads/')
            }
        })()
    }, [leadId])


    const navigate = useNavigate()

    React.useEffect(() => {
        (async () => {
            try {
                setLoading(true)
                const result = await hooks.getSellerDashboard()
                if (result && result.agency) {
                    const status = hooks.checkAgencyStatus(result.agency, navigate)

                    setSalesLeads(result.availableSalesLeads)
                    setPurchasedSalesLeads(result.purchasedSalesLeads)
                    setAgency(result.agency)
                    setAgent(result.agent)

                    if (status) setLoading(false)
                }
                setLoading(false)
            } catch (error) {
                navigate('/error')
            }
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <AppBody loading={loading}>
            <Stack tokens={{ childrenGap: 0 }}>
                {agency && <Stack tokens={{ childrenGap: SmallGap }}>
                    <Stack.Item><span>Du har adgang til at købe leads i følgende områder:</span></Stack.Item>
                    <Stack horizontal wrap tokens={{ childrenGap: SmallGap }}>
                        {agency && agency.operatingAreas.map((item, key) => <Tags.Tag key={key}><span>{zipcodeLib.getZipDisplayName(item)}</span></Tags.Tag>)}
                    </Stack>
                </Stack>}
                <Stack.Item><h2>Købte salgsvurderinger</h2></Stack.Item>
                {purchasedSalesLeads && purchasedSalesLeads.length === 0 ? <span>Du har ikke indkøbt leads endnu</span> : <ShimmeredDetailsList
                    items={!purchasedSalesLeads ? [] : purchasedSalesLeads.map((item, key) => ({
                        address: item.lead.address, created: DateStringToNiceDate(item.user.createdAt),
                        details: <SellerPanelView user={item.user} key={key} />
                    }))}
                    columns={defaultColumns}
                    selectionMode={SelectionMode.none}
                    enableShimmer={loading}
                />}
                <Stack.Item><h2>Salgvurderinger til salg</h2></Stack.Item>
                <Stack.Item >
                    <span>En boligsøgende i dit lokalområde har bedt om en salgsvurdering af deres bolig og derfor ses disse salgsvurderinger nedenfor. Det vil dog kun være 3 af jer lokale mæglere, som kan købe hvert enkelt salgsvurdering, så det er først-til-mølle princippet.</span>
                    <span>Derfor er det vigtigt at du hele tiden tjekker din Venando profil, så du kan være klar på tasterne og købe disse salgsvurderinger</span>
                </Stack.Item>
                <Stack.Item>
                    {salesLeads && salesLeads.length === 0 ? <span>Ingen leads tilgænglige i dit område</span> : <ShimmeredDetailsList
                        items={!salesLeads ? [] : salesLeads.map((item, key) => ({
                            address: zipcodeLib.getZipDisplayName(item.areaId), created: DateStringToNiceDate(item.createdAt),
                            details: <NewSellerPanelView userId={item.userId} areaId={item.areaId} key={key} />
                        }))}
                        columns={defaultColumns}
                        selectionMode={SelectionMode.none}
                        enableShimmer={loading}
                    />}
                </Stack.Item>
            </Stack>

        </AppBody>
    )
}

export default SellersPage