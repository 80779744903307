import * as React from 'react'
import { PrimaryButton, Stack } from '@fluentui/react'
import { RootBody } from '../components/wrappers'
import { LargeGap, SmallGap } from '../utils/globals';
import NavBar from '../components/NavComponents';
import { useNavigate } from 'react-router-dom';


const PendingApprovalPage: React.FC = () => {
    const navigate = useNavigate()

    return (
        <RootBody>
            <Stack style={{ padding: SmallGap }}>
                <NavBar />
                <Stack tokens={{ childrenGap: LargeGap }}>
                    <Stack.Item>
                        <h2>Vi godkender din profil hurtigst muligt</h2>
                        <p>For at opretholde høj kvalitet af indhold og ejendomsmæglere på Venando, foretager vi manuel godkendelse af alle nye mæglere på vores platform.</p>
                        </Stack.Item>
                    <Stack.Item>
                        <span>Mener du at dette er en fejl? Du kan sende din fejlbesked via følgende link: </span>
                        <a href={'mailto:web@venando.dk?subject=Fejl på Venando godkendelse'}>Raporter fejl til Supporten</a>
                    </Stack.Item>
                    <Stack.Item>
                        <PrimaryButton text='Genindlæs siden' onClick={() => navigate('/')} />
                        </Stack.Item>
                </Stack>
            </Stack>

        </RootBody>
    )
}

export default PendingApprovalPage