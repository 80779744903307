import {
    DashboardData, FileResponse, Agent, AgencyRootData, Agency, AgencyLookupData,
    AgencySelectPlanProperties, LoginChallengeRequest, LoginChallengeResponse, LoginAnswerChallengeRequest,
    LoginAnswerChallengeResponse, UpdatableAgentProperties, AgentAndAgency, UpdatableAgencyProperties, AdminUpdatableAgentProperties, AgentId, AgentSellerDashboardData, AgentRootData,
    VenandoAgencyPrice
} from '@venando/common';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

const AuthConfigs: AxiosRequestConfig = ({ headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }, withCredentials: true })

const navigate = (location: string) => setTimeout(function () { document.location.href = location }, 500);

const getEndpoint = () => {
    const enviroment = process.env.REACT_APP_API
    if (enviroment === "local-dev") return "http://localhost:3000/dev/"
    if (enviroment === "local-prod") return "http://localhost:3000/prod/"
    if (enviroment === "dev") return "https://ktzxnrlqw1.execute-api.eu-west-1.amazonaws.com/dev/"
    return "https://eqd2dss0gh.execute-api.eu-west-1.amazonaws.com/prod/"
}

export const endpoint = getEndpoint()

export async function wrapper<T>(func: () => Promise<T & { error?: string }>): Promise<T | undefined> {
    try {
        const result = await func()
        if (result.error) { console.log(result.error); return undefined }
        return result
    } catch (error) {
        return undefined
    }
}

async function authorizedWrapper<T>(func: () => Promise<AxiosResponse<T> | void>): Promise<T | undefined> {
    try {
        const result = await func()
        if (result && result.data) return result.data
    } catch (error) {
        if (error instanceof AxiosError) {
            if (error.response?.status === 401) navigate('/login')
            if (error.response?.status === 500) navigate('/error?' + encodeURIComponent(JSON.stringify(error.response.data)))

            return undefined
        }
        return undefined
    }
    return undefined
}

const testLogin = async (): Promise<Agent | undefined> => await wrapper(async () => {
    const result = await axios.get<Agent>(endpoint + 'agent', AuthConfigs)
    return result.data
})

const getUser = async (): Promise<Agent | undefined> => await authorizedWrapper(async () => {
    const result = await axios.get<Agent>(endpoint + 'agent', AuthConfigs)
    return result
})

const getAgentAndAgency = async (): Promise<AgentAndAgency | undefined> => await authorizedWrapper(async () => {
    const result = await axios.get<AgentAndAgency>(endpoint + 'agent/detailed', AuthConfigs)
    return result
})

export type CVRApiItem = {
    vat: number,
    name: string,
    address: string,
    zipcode: number,
    city: string,
    email?: string,
    phone?: string,
    industrydesc: string
}
const searchCVR = async (term: string): Promise<CVRApiItem[] | undefined> => await wrapper(async () => {

    const result = await axios.get<CVRApiItem[]>(endpoint + 'cvr-search/' + term)

    return result.data
})

export type WarningStorageKey = "locationsWarningRemoved" | "newUI"
const closeWarning = (warning: WarningStorageKey) => localStorage.setItem(warning, "closed")
const isWarningClosed = (warning: WarningStorageKey) => localStorage.getItem(warning) !== null


const startLogin = async (props: LoginChallengeRequest): Promise<LoginChallengeResponse | undefined> => await wrapper(async () => {
    const result = await axios.post<LoginChallengeResponse>(endpoint + 'agent/authenticate', props, { withCredentials: true })
    return result.data
})

const confirmUser = async (props: LoginAnswerChallengeRequest): Promise<LoginAnswerChallengeResponse | undefined> => await wrapper(async () => {
    const result = await axios.post<LoginAnswerChallengeResponse>(endpoint + 'agent/authentication/respond', props, { withCredentials: true })
    if (result.data.token) localStorage.setItem('token', result.data.token)
    return result.data
})

const getDashboardData = async () => await authorizedWrapper(async () => {
    const result = await axios.get<DashboardData>(endpoint + 'dashboard', AuthConfigs)
    if (result && result.data && result.data.agency) return result
    else if (result && result.data && result.data.agent) {
        navigate('/business-signup')
    }
})
const selectAgencyPlan = async (product: string, extra: string[]): Promise<string | undefined> => await authorizedWrapper(async () => {
    const props: AgencySelectPlanProperties = { mainSubscriptionPlan: product, extraSubscriptions: extra }
    const result = await axios.post<string>(endpoint + 'select-plan', props, AuthConfigs)
    return result
})

const checkoutSubscription = async (): Promise<string | undefined> => await authorizedWrapper(async () => {
    const result = await axios.get<string>(endpoint + 'creditcard', AuthConfigs)
    return result
})

const getBillingSessionURL = async (): Promise<string | undefined> => await authorizedWrapper(async () => {
    const result = await axios.get<string>(endpoint + 'billing-session/', AuthConfigs)
    return result
})

const validateSubscription = async (): Promise<boolean | undefined> => await authorizedWrapper(async () => {
    const result = await axios.get<boolean>(endpoint + 'validate-card-setup/', AuthConfigs)
    return result
})

const updateAgent = async (props: UpdatableAgentProperties): Promise<Agent | undefined> => await authorizedWrapper(async () => {
    const result = await axios.put<Agent>(endpoint + 'agent', props, AuthConfigs)
    return result
})

const getProducts = async (coupon?: string): Promise<VenandoAgencyPrice | undefined> => await authorizedWrapper(async () => {
    let url = 'products/agency'
    if (coupon) url = 'products/agency/' + coupon
    const result = await axios.get<VenandoAgencyPrice>(endpoint + url, AuthConfigs)
    return result
})

const uploadFile = async (file: File): Promise<FileResponse | undefined> => await authorizedWrapper(async () => {
    const result = await axios.get<FileResponse>(endpoint + 'file', AuthConfigs)
    if (result) {
        await axios.put(result.data.uploadUrl, file)
    }
    return result
})

const createAgency = async (props: AgencyRootData): Promise<Agency | undefined> => await authorizedWrapper(async () => {
    const result = await axios.post<Agency>(endpoint + 'agency', props, AuthConfigs)
    return result
})

const searchAgency = async (term: string): Promise<AgencyLookupData[] | undefined> => await authorizedWrapper(async () => {
    const result = await axios.get<AgencyLookupData[]>(endpoint + 'agency/lookup/' + term, AuthConfigs)
    return result
})

const requestAgencyAccess = async (agencyId: string): Promise<Agency | undefined> => await authorizedWrapper(async () => {
    const result = await axios.post<Agency>(endpoint + 'agency-access/' + agencyId, {}, AuthConfigs)
    return result
})

const logout = () => {
    localStorage.removeItem('token')
    navigate('/login')
}

const getAgencyAgents = async (): Promise<Agent[] | undefined> => await authorizedWrapper(async () => {
    const result = await axios.get<Agent[]>(endpoint + 'agency/agents', AuthConfigs)
    return result
})

const updateAgency = async (props: UpdatableAgencyProperties): Promise<Agency | undefined> => await authorizedWrapper(async () => {
    const result = await axios.put<Agency>(endpoint + 'agency', props, AuthConfigs)
    return result
})

const updateAgencyAgent = async (props: AdminUpdatableAgentProperties & AgentId): Promise<Agent | undefined> => await authorizedWrapper(async () => {
    const result = await axios.post<Agent>(endpoint + 'agency-access/respond', props, AuthConfigs)
    return result
})

const getSellerDashboard = async (): Promise<AgentSellerDashboardData | undefined> => await authorizedWrapper(async () => {
    const result = await axios.get<AgentSellerDashboardData>(endpoint + 'dashboard/seller', AuthConfigs)
    return result
})

const completeSellerPurchase = async (userId: string): Promise<string | undefined> => await authorizedWrapper(async () => {
    const result = await axios.get<string>(endpoint + 'seller-lead/confirm/' + userId, AuthConfigs)
    return result
})


const startSellerPurchase = async (userId: string): Promise<string | undefined> => await authorizedWrapper(async () => {
    const result = await axios.get<string>(endpoint + 'seller-lead/buy/' + userId, AuthConfigs)
    return result
})

const inviteAgentForAgency = async (props: AgentRootData): Promise<boolean | undefined> => await authorizedWrapper(async () => {
    const result = await axios.post<boolean>(endpoint + 'agent/invite', props, AuthConfigs)
    return result
})


const checkAgencyStatus = (agency: Agency, navigation: (destination: string) => void) => {
    if (!agency.mainSubscriptionPlan) { navigation('/select-product'); return false }
    if (!agency.validSubscription) { navigation('/validate-subscription'); return false }
    else if (!agency.approved) { navigation('/pending-approval'); return false }
    return true
}

const deleteAgent = async (agentId: string): Promise<void> => await authorizedWrapper(async () => {
    await axios.put(endpoint + 'remove-agency-access/' + agentId, {}, AuthConfigs)
})



const hooks = {
    getUser, confirmUser, startLogin, getDashboardData, uploadFile, updateAgent, searchCVR, createAgency,
    searchAgency, requestAgencyAccess, logout, getAgencyAgents, selectAgencyPlan, getBillingSessionURL, checkoutSubscription,
    validateSubscription, testLogin, getAgentAndAgency, updateAgency, updateAgencyAgent, getSellerDashboard,
    completeSellerPurchase, startSellerPurchase, inviteAgentForAgency, isWarningClosed, closeWarning,
    navigate, checkAgencyStatus, deleteAgent, getProducts
}

export default hooks