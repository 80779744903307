import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import hooks from '../utils/hooks'
import { Stack } from '@fluentui/react'
import { AppBody } from '../components/wrappers'
import { Agency, Agent } from '@venando/common'
import EditAccessComponent from '../components/EditAccessComponent'
import { LargeGap } from '../utils/globals'


const EditCompanyAccessPage: React.FC = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = React.useState(false)
    const [agency, setAgency] = React.useState<Agency>()
    const [agent, setAgent] = React.useState<Agent>()

    React.useEffect(() => {
        (async () => {
            if (loading) return
            setLoading(true)
            try {
                const result = await hooks.getAgentAndAgency()
                if (result && result.agency) {
                    const status = hooks.checkAgencyStatus(result.agency, navigate)
                    setAgency(result.agency)
                    setAgent(result.agent)
                    if(status) setLoading(false)
                }
            } catch (error) {
                navigate('/error')
            }
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <AppBody loading={loading}>
            <Stack tokens={{ childrenGap: LargeGap }}>
                <EditAccessComponent agency={agency} agent={agent} updateAgency={setAgency} />
            </Stack>
        </AppBody>
    )
}

export default EditCompanyAccessPage