import * as React from 'react'
import { DefaultButton, FontIcon, SharedColors, Shimmer, Spinner, SpinnerSize, Stack, StackItem, TextField } from "@fluentui/react"
import { SmallGap } from "../utils/globals"
import { ErrorContext } from './wrappers'
import hooks from '../utils/hooks'

export const isFieldInvalid = (field?: string) => !field || field.length < 3

export const validateEmail = (email?: string):boolean => {
    if (!email) return false
    const result =  String(email).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    return !!result
}

type InputProps = { value?: string, setValue?: (val?: string) => void, label: string, loading?: boolean, disable?: boolean, type?: string }

export const InputWrapper: React.FC<{ label: string, loading: boolean, children: React.ReactNode | React.ReactNode[] }> = ({ label, loading, children }) => {
    return (
        <Stack style={{ marginTop: SmallGap }} tokens={{ childrenGap: SmallGap }}>
            <StackItem><span>{label}</span></StackItem>
            <StackItem>{loading ? <Shimmer style={{ height: 32 }} /> : children}</StackItem>
        </Stack>
    )
}

export const InputWithLabel: React.FC<InputProps> = ({ value, setValue, loading, label, disable, type }) => {

    return (
        <InputWrapper loading={!!loading} label={label}>
            <TextField disabled={disable} onChange={(_e, val) => { if (setValue) setValue(val) }} value={value} type={type} />
        </InputWrapper>
    )

}


export const MultilineInputWithLabel: React.FC<InputProps> = ({ value, setValue, loading, label, disable }) => {
    return (
        <InputWrapper loading={!!loading} label={label}>
            <TextField disabled={disable} multiline onChange={(_e, val) => { if (setValue) setValue(val) }} value={value} />
        </InputWrapper>
    )
}


const imageFileTypes = ["image/png", "image/jpeg"];

export const FileTypes = { imageFileTypes }
export const FileInput: React.FC<{ value?: string, setter: (val: string) => void, loading: boolean, allowedFileTypes: string[] }> = ({ loading, value, setter, allowedFileTypes }) => {
    const { showErrorMessage } = React.useContext(ErrorContext)

    const [succes, setSuccess] = React.useState(!!value)
    const [uploading, setUploading] = React.useState(false)
    const [uploadingJustCompleted, setUploadingJustCompleted] = React.useState(false)


    React.useEffect(() => {
        if (value) setSuccess(true)
    }, [value])

    const fileRef = React.useRef<HTMLInputElement>(null)

    const handleClick = () => fileRef.current?.click()

    const handleUpload = async (value: FileList | null) => {
        if (!value) return
        const file = value[0]
        if (!allowedFileTypes.includes(file.type)) { showErrorMessage("Dette filformat er ikke tilladt. Det er kun muligt at uploade PNG og JPEG filer"); return }
        setUploading(true)
        const result = await hooks.uploadFile(file)

        if (result?.key) {
            setSuccess(true)
            setter(result.downloadUrl)
        }
        setUploading(false)
        setUploadingJustCompleted(true)
    }


    return (
        <Stack.Item>{loading ? <Shimmer style={{ height: 32 }} /> : (
            <Stack horizontal verticalAlign='center'>
                <input ref={fileRef} type='file' style={{ display: 'none' }} onChange={(e) => handleUpload(e.target.files)} />
                {uploading ? (
                    <Spinner size={SpinnerSize.medium} style={{ height: 32 }} />
                ) : (
                    <><DefaultButton text='Upload' onClick={handleClick} />
                        {succes ? (
                            <>
                                <FontIcon iconName="SkypeCircleCheck" style={{ color: SharedColors.green10, paddingLeft: SmallGap, paddingRight: SmallGap }} />
                                <span>{uploadingJustCompleted ? "Fil uploaded, husk at gemme ændringer" : "Fil uploaded"}</span>
                            </>
                        ) : (
                            <>
                                <FontIcon iconName="SkypeCircleMinus" style={{ color: SharedColors.red10, paddingLeft: SmallGap, paddingRight: SmallGap }} />
                                <span>Upload fil</span>
                            </>
                        )}
                    </>
                )}
            </Stack>
        )}</Stack.Item>
    )
}