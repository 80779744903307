import * as React from 'react'
import { ActionButton, Dialog, DialogFooter, DialogType, Dropdown, List, Panel, PanelType, PrimaryButton, SharedColors, Shimmer, Stack, StackItem } from '@fluentui/react'
import hooks from '../utils/hooks';
import { Agent, Agency } from '@venando/common';
import { AgencyRoles } from '../utils/variables';
import { LargeGap, SmallGap } from '../utils/globals';
import { InputWithLabel, MultilineInputWithLabel } from './Fields';
import { DateStringToNiceDate } from '../utils/formatters';
import { ButtonTypes, ButtonWithLoading } from './Button';


export const AdminUserEditor: React.FC<{ agent: Agent, disabled?: boolean, onReload(): Promise<void> }> = ({ agent, disabled, onReload }) => {
    const [name, setName] = React.useState<string | undefined>(agent.agentProfileName)
    const [phone, setPhone] = React.useState<string | undefined>(agent.phone)
    const [role, setRole] = React.useState<string | undefined>(agent.agencyRole)
    const [description, setDescription] = React.useState<string | undefined>(agent.agentProfileDescription)

    const handleSubmit = async () => {
        const updatedAgent = await hooks.updateAgencyAgent({ agentId: agent.agentId, agencyRole: role, agentProfileName: name, agentProfileDescription: description, phone })
        await onReload()
        if (updatedAgent) {
            setName(updatedAgent.agentProfileName)
            setRole(updatedAgent.agencyRole)
            setDescription(updatedAgent.agentProfileDescription)
        }
    }

    const handleDelete = async () => {
        await hooks.deleteAgent(agent.agentId)
        await onReload()
    }

    const isEdited = role !== agent.agencyRole || description !== agent.agentProfileDescription || name !== agent.agentProfileName || phone !== agent.phone

    return (
        <Stack tokens={{ childrenGap: SmallGap }}>
            <StackItem><span>Oprettet: {DateStringToNiceDate(agent.createdAt)}</span></StackItem>
            <Stack tokens={{ childrenGap: SmallGap }} horizontal verticalAlign='center'>
                <img src={agent.agentProfileImage} width={50} height={50} style={{ borderRadius: 200 }} alt='agent' />
                <b>{agent.agentProfileName}</b>
            </Stack>
            <span style={{ color: "#84889a", fontSize: 12 }}>{agent.agentProfileDescription}</span>

            <InputWithLabel value={agent.email} label='Email' disable />
            <InputWithLabel value={agent.phone} label='Telefonnummer' setValue={setPhone} disable={disabled} />
            <InputWithLabel value={name} label='Navn' setValue={setName} disable={disabled} />
            <MultilineInputWithLabel value={description} label='Beskrivelse' setValue={setDescription} disable={disabled} />

            <Dropdown label='Rolle' selectedKey={role} disabled={disabled}
                onChange={(e, item) => setRole(item?.key.toString())}
                options={[{ key: AgencyRoles.Requester, text: "Adgang anmodet" }, { key: AgencyRoles.Member, text: "Medlem" }, { key: AgencyRoles.Admin, text: "Administrator" }]} />
            <ButtonWithLoading onClick={handleSubmit} text='Gem ændringer' disabled={!isEdited} />
            {!disabled && <ButtonWithLoading onClick={handleDelete} text='Slet bruger' buttonType={ButtonTypes.text} />}

            {disabled && <i>Du kan ikke redigerer denne bruger</i>}
        </Stack>
    )
}

const EditAccessComponent: React.FC<{ agent?: Agent, agency?: Agency, updateAgency(updated: Agency): void }> = ({ agent }) => {
    const [agencyAgents, setAgencyAgents] = React.useState<Agent[] | undefined>()
    const [selectedAgent, setSelectedAgent] = React.useState<Agent | undefined>()
    const [editorOpen, setEditorOpen] = React.useState<boolean | undefined>()
    const [inviteModalOpen, setInviteModalOpen] = React.useState<boolean | undefined>()
    const [inviteEmail, setInviteEmail] = React.useState<string | undefined>()


    const getAccess = async () => {
        const result = await hooks.getAgencyAgents()
        if (result) {
            const sortedAgencyAgents = result.sort((a, b)=> (a.agentProfileName||'').localeCompare(b.agentProfileName||''))
            setAgencyAgents(sortedAgencyAgents)
            const updatedLocalAgent = result.find((i) => i.agentId === selectedAgent?.agentId)
            if (updatedLocalAgent) setSelectedAgent(updatedLocalAgent)
        }
    }

    const sendEmailInvite = async () => {
        if (!inviteEmail) return
        const result = await hooks.inviteAgentForAgency({ email: inviteEmail })
        if (result) await getAccess()
        setInviteModalOpen(false)
        setInviteEmail('')
    }

    React.useEffect(() => {
        getAccess()
        //eslint-disable-next-line
    }, [])

    if (!agent) return <Shimmer />

    if (agent.agencyRole !== AgencyRoles.Admin) return <span>Denne funktionalitet kræver administrator rettigheder</span>

    if (!agencyAgents) return <Shimmer />

    return (
        <Stack style={{maxWidth: 700}}>
            <Stack style={{ width: '100%', marginBottom: LargeGap }} tokens={{ childrenGap: SmallGap }}>
                <Stack.Item><h2>Bruger</h2></Stack.Item>
                <Stack.Item><span>Her kan du invitere dine sælgere på kontoret til systemet. Det betyder at de kan oprette sig og få sit eget login til Venando.</span></Stack.Item>
            </Stack>
            <Stack horizontal horizontalAlign='space-between' verticalAlign='center' tokens={{ childrenGap: SmallGap }}
                style={{ paddingBottom: 5, borderBottomWidth: 1, borderBottomColor: SharedColors.gray10, borderBottomStyle: 'solid' }}>
                <Stack.Item>
                    <b>Navn/email</b>
                </Stack.Item>
                <Stack horizontal tokens={{ childrenGap: LargeGap }} verticalAlign='center'>
                    <Stack.Item>
                        <b>Rolle</b>
                    </Stack.Item>
                    <Stack.Item>
                        <b style={{ marginLeft: LargeGap }}>Rediger</b>
                    </Stack.Item>
                </Stack>
            </Stack>
            <List items={agencyAgents} onRenderCell={(item) => item && (
                <Stack horizontal horizontalAlign='space-between' verticalAlign='center' tokens={{ childrenGap: SmallGap }}
                    style={{ marginBottom: 5, borderBottomWidth: 1, borderBottomColor: SharedColors.gray10, borderBottomStyle: 'solid' }}>
                    <Stack.Item>
                        <span>{agent.agentId === item.agentId && (<b>Dig </b>)}{item.agentProfileName || <span style={{color: 'red'}}>Unavngivet</span>} ({item.email})</span>
                    </Stack.Item>
                    <Stack horizontal tokens={{ childrenGap: LargeGap }} verticalAlign='center'>
                        <Stack.Item>
                            <span style={{ backgroundColor: SharedColors.gray10, paddingLeft: SmallGap, paddingRight: SmallGap, color: '#fff', borderRadius: SmallGap }}>{item.agencyRole}</span>
                        </Stack.Item>
                        <Stack.Item>
                            <ActionButton iconProps={{ iconName: 'edit' }} onClick={() => { setSelectedAgent(item); setEditorOpen(true) }}>
                                Rediger
                            </ActionButton>
                        </Stack.Item>
                    </Stack>
                </Stack>
            )} />
            <Stack style={{ marginTop: LargeGap }}>
                <Stack.Item>
                    <PrimaryButton onClick={() => setInviteModalOpen(true)}>Inviter bruger</PrimaryButton>
                </Stack.Item>
            </Stack>
            <Panel type={PanelType.medium} headerText="Rediger bruger" isOpen={editorOpen} isLightDismiss
                onDismiss={() => setEditorOpen(false)} closeButtonAriaLabel="Close" >
                {selectedAgent && <AdminUserEditor agent={selectedAgent} disabled={selectedAgent.agentId === agent.agentId} onReload={getAccess} />}
            </Panel>

            <Dialog hidden={!inviteModalOpen}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: 'Inviter ny bruger',
                    closeButtonAriaLabel: 'Close',
                    subText: 'Når du invitere en ny bruger vil de automatisk blive oprettet og få adgang til din butik. Du kan senere give dem administrator adgang',
                }}
                onDismiss={() => setInviteModalOpen(false)}>
                <InputWithLabel label={'Email du ønsker at invitere'} value={inviteEmail} setValue={setInviteEmail} />
                <DialogFooter>
                    <ButtonWithLoading onClick={sendEmailInvite} disabled={!inviteEmail || inviteEmail.length < 4 || !inviteEmail.includes('@') || !inviteEmail.includes('.')} text='Send invitation' />
                </DialogFooter>
            </Dialog>
        </Stack>
    )

}

export default EditAccessComponent