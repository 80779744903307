import * as React from 'react'
import { LargeGap, SmallGap, VenandoLogo } from '../utils/globals'
import { DefaultButton, Stack } from '@fluentui/react';
import { useNavigate } from 'react-router-dom';

const NavBar: React.FC = () => {
    const navigate = useNavigate()

    return (
        <Stack horizontal tokens={{ childrenGap: SmallGap }} verticalAlign='center' style={{ justifyContent: 'space-between', marginBottom: SmallGap }}>
            <Stack horizontal tokens={{ childrenGap: LargeGap }} verticalAlign='center'>
                <Stack.Item>
                    <a href='/'>
                    <img alt="venando logo" src={VenandoLogo}
                        style={{ width: 140, paddingBottom: 20 }} />
                        </a>
                </Stack.Item>
            </Stack>
            <Stack horizontal tokens={{ childrenGap: LargeGap }} verticalAlign='center'>
                <Stack.Item>
                    <DefaultButton onClick={() => navigate('/logout')} text='Log ud' />
                </Stack.Item>
            </Stack>
        </Stack>
    )
}

export default NavBar