import * as React from 'react'
import { LoginBody } from '../components/wrappers'
import SignupAgencyComponent from '../components/SignupAgencyComponent'
import hooks from '../utils/hooks'
import { useNavigate } from 'react-router-dom'
import { Pivot, PivotItem } from '@fluentui/react'
import RequestAccessToAgencyComponent from '../components/RequestAccessToAgencyComponent'
import { SignOutButton } from '../components/Button'
import { AgencyRoles } from '../utils/variables'



const SignupBusinessPage: React.FC = () => {
    const navigate = useNavigate()

    React.useEffect(() => {
        (async () => {
            const result = await hooks.testLogin()
            if (!result) navigate('/login')
            else if(result.agencyId && result.agencyRole === AgencyRoles.Requester) navigate('/pending-approval')
            else if(result.agencyId) navigate('/')

        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <LoginBody>
            <Pivot>
                <PivotItem headerText="Opret forretning">
                    <SignupAgencyComponent />
                </PivotItem>
                <PivotItem headerText="For medarbejder">
                    <RequestAccessToAgencyComponent />
                </PivotItem>
            </Pivot>

            <SignOutButton customText={"Annuller oprettelsen"}/>
        </LoginBody >
    )
}

export default SignupBusinessPage