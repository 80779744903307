
//Manually shared between frontend and backend
export enum AgencyRoles {
    Admin = "Admin",
    Member = "Member",
    Requester = "Rquester"
}

export enum Plans {
    yearly = 'yearly',
    monthly = 'monthly',
}

export type Product = {
    text: string,
    key: Plans | string,
    price: number
}

export const mainProducts: [Product] = [
    { text: 'Månedlig betaling - 1.000 kr', key: Plans.monthly, price: 1000 }
]