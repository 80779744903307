import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import hooks, { WarningStorageKey } from '../utils/hooks'
import { MessageBar, MessageBarType, Stack } from '@fluentui/react'
import { AppBody } from '../components/wrappers'
import CustomerList from '../components/CustomerList'
import { Agency, Agent, SearchSetting, User } from '@venando/common'
import Tags from '../components/Tags'
import zipcodeLib from '../utils/zipcode-lib'
import { LargeGap, SmallGap } from '../utils/globals'


const RootWarning: React.FC<{ warning: WarningStorageKey, content: React.ReactNode, type: MessageBarType }> = ({ warning, content, type }) => {
    const [isClosed, setIsClosed] = React.useState(hooks.isWarningClosed(warning))

    const handleClose = () => {
        setIsClosed(true)
        hooks.closeWarning(warning)
    }

    if (isClosed) return <></>

    return (
        <MessageBar onDismiss={handleClose} messageBarType={type}>
            <Stack.Item>{content}</Stack.Item>
        </MessageBar>
    )

}

const LocationWarning = <RootWarning type={MessageBarType.info} warning='locationsWarningRemoved' content={<span>Ønsker du adgang til flere områder, så<a target='_blank' rel='noreferrer' href='https://www.venando.dk/kontakt'>kontakt os her</a></span>} />

const NewUIWarning = <RootWarning type={MessageBarType.success} warning='newUI' content={<span>🎉 Vi har opdateret søgefunktionerne på dit dashboard. Hvis du har feedback, er du meget velkommen til at<a target='_blank' rel='noreferrer' href='https://www.venando.dk/kontakt'>kontakte os her</a></span>} />


const Dashboard: React.FC = () => {
    const [loading, setLoading] = React.useState(true)
    const [agency, setAgency] = React.useState<Agency>()
    const [agent, setAgent] = React.useState<Agent>()
    const [searchSetting, setSearchSetting] = React.useState<SearchSetting>({ sorting: 'created-date' })


    const [buyerLeads, setBuyerLeads] = React.useState<User[]>([])
    const navigate = useNavigate()

    React.useEffect(() => {
        (async () => {
            try {
                setLoading(true)
                const result = await hooks.getDashboardData()
                if (result && result.agency) {
                    const status = hooks.checkAgencyStatus(result.agency, navigate)
                    setAgency(result.agency)
                    if (result.agent) setAgent(result.agent)
                    if (result?.buyerLeads) {
                        const sortedBuyerLeads = result.buyerLeads.sort((a, b) => ((new Date(b.createdAt)).getTime() - (new Date(a.createdAt)).getTime()))
                        setBuyerLeads(sortedBuyerLeads)
                    }
                    if (status) setLoading(false)
                }
            } catch (error) {
                navigate('/error')
            }
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <AppBody loading={loading}>
            <Stack tokens={{ childrenGap: LargeGap }}>
                {agency && <Stack tokens={{ childrenGap: SmallGap }}>
                    {LocationWarning}
                    {NewUIWarning}
                    <Stack.Item>Velkommen <b>{agent?.agentProfileName} {agency && "(" + agency.companyProfileName + ")"}</b></Stack.Item>
                    <Stack.Item><span>Du har adgang til følgende områder:</span></Stack.Item>
                    <Stack horizontal wrap tokens={{ childrenGap: SmallGap }}>
                        {agency?.operatingAreas.map((item, key) => <Tags.Tag key={key}><span>{zipcodeLib.getZipDisplayName(item)}</span></Tags.Tag>)}
                    </Stack>
                </Stack>}
                <Stack tokens={{ childrenGap: SmallGap }}>
                    {buyerLeads && buyerLeads.length > 0 && <CustomerList loading={loading} customers={buyerLeads} searchSetting={searchSetting} setSearchSetting={setSearchSetting} validAreas={agency?.operatingAreas || []} />}
                    {!loading && buyerLeads.length === 0 && <Stack.Item><p>Nye købere i dit område er på vej</p></Stack.Item>}
                </Stack>
            </Stack>
        </AppBody >
    )
}

export default Dashboard