import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import hooks from '../utils/hooks'
import { Stack } from '@fluentui/react'
import { AppBody } from '../components/wrappers'
import EditAgent from '../components/EditAgent'
import { Agency, Agent } from '@venando/common'
import { SmallGap } from '../utils/globals'


const EditProfilePage: React.FC = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = React.useState(true)

    const [agent, setAgent] = React.useState<Agent>()
    const [agency, setAgency] = React.useState<Agency>()

    React.useEffect(() => {
        (async () => {
            setLoading(true)
            try {
                const result = await hooks.getAgentAndAgency()
                if (result) {
                    const status = hooks.checkAgencyStatus(result.agency, navigate)
                    
                    setAgent(result.agent)
                    setAgency(result.agency)

                    if(status) setLoading(false)
                }

            } catch (error) {
                navigate('/error')
            }
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <AppBody loading={loading}>
            <Stack horizontal>
                <Stack tokens={{childrenGap: SmallGap}} style={{maxWidth: 700}}>
                    <Stack.Item><h2>Rediger din profil</h2></Stack.Item>
                    <Stack.Item><span>Venando er mere end bare en Lead-Generator. Vi ønsker nemlig at eksponere dig som Lokal ejendomsmægler.</span></Stack.Item>
                    <Stack.Item><span>Det er derfor vigtigt at du får udfyldt info og vedhæftet et billede så køberne kan knytte en relation allerede fra start når de skriver sig op i din by. Her under din mægler profil vil du blive vist når købere opretter sig, og du vil ligge som visitkort på købernes profil.</span></Stack.Item>
                    <EditAgent agent={agent} agency={agency} updateAgent={setAgent} />
                </Stack>
            </Stack>
        </AppBody>
    )
}

export default EditProfilePage