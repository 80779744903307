import * as React from 'react';
import { PrimaryColor, SmallGap } from '../utils/globals';
import { Stack } from '@fluentui/react';


const Tag: React.FC<{ children: React.ReactNode, backgroundColor?: string }> = ({ children, backgroundColor }) => {

    return (
        <div style={{ backgroundColor: backgroundColor || PrimaryColor, color: "#fff", paddingLeft: SmallGap, paddingRight: SmallGap, paddingTop: 3, paddingBottom: 3, borderRadius: 12}}>
            {children}
        </div>
    )
}

const TagList: React.FC<{ backgroundColor?: string, selected: string[], options?: ({ id: string, name: string })[]}> = ({ backgroundColor, selected, options }) => {

    const getOptionName = (id: string) => options?.find((i) => i.id === id)?.name || id

    return (
        <Stack wrap horizontal tokens={{ childrenGap: SmallGap }}>
            {selected.map((item, key) => <Stack.Item key={key}><Tag backgroundColor={backgroundColor} ><span >{getOptionName(item)}</span></Tag></Stack.Item>)}
        </Stack>
    )
}

const out = { Tag, TagList }

export default out 