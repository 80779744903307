import { Nav, PrimaryButton, Spinner, SpinnerSize, Stack } from '@fluentui/react'
import * as React from 'react'
import { LargeGap, PrimaryColor, SmallGap, VenandoLogo, dynamicFormWidth, isMobile } from '../utils/globals';
import NavBar from './NavComponents';
import { useNavigate } from 'react-router-dom';

export const ErrorContext = React.createContext<{ showErrorMessage(message: string): void }>({ showErrorMessage: console.log });


export const Root: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [errorMessages, setErrorMessages] = React.useState<string[]>([])

    const showErrorMessage = (message: string) => {
        setErrorMessages((current) => [...current, message])
    }

    return (
        <ErrorContext.Provider value={{ showErrorMessage }}>
            {errorMessages.map((i, key) => <p key={key}>{i}</p>)}
            {children}
        </ErrorContext.Provider>
    )
}

const logoURL = "https://uploads-ssl.webflow.com/64f8671b332495b34b825240/64f879b2332495b34b9a63ba_venando_logo-p-500.png"

export const LoginBody: React.FC<{ children: React.ReactNode }> = ({ children }) => {

    const useMobileConfig = window.innerWidth < 550

    if (useMobileConfig) return (
        <Root>
            <Stack tokens={{ childrenGap: LargeGap }} style={{ backgroundColor: '#fff', paddingBottom: 120, paddingTop: 32, paddingLeft: 12, paddingRight: 12 }}>
                <Stack horizontalAlign='center'>
                    <img width={window.innerWidth / 3} src={logoURL} alt='Venando logo' />
                </Stack>
                <Stack.Item style={{overflow: 'hidden'}}>
                    {children}
                </Stack.Item>
            </Stack>
        </Root>
    )

    return (
        <Root>
            <div style={{
                display: 'flex', flexDirection: 'row-reverse',
                width: '100%', height: '100vh', backgroundColor: PrimaryColor,
                backgroundImage: "url(https://uploads-ssl.webflow.com/64f8671b332495b34b825240/64f8e9f7ae024a1306d401f4_krisztian-tabori-5WzjEj5ygts-unsplash.jpg)",
                backgroundPosition: "center", backgroundSize: 'cover'
            }}>
                <Stack.Item style={{ minWidth: 400, paddingLeft: 30, paddingRight: 60, paddingTop: 60, paddingBottom: 60, backgroundColor: "#fff", overflowY: 'scroll' }}>
                    <Stack horizontalAlign='center'>
                        <img alt="venando logo" src={logoURL} style={{ width: 200, paddingBottom: 20 }} />
                    </Stack>
                    <Stack style={{ marginBottom: 120, overflowY: 'scroll', width: dynamicFormWidth }}>
                        {children}
                    </Stack>
                </Stack.Item>
            </div>
        </Root>
    )
}

export const CustomSpinner: React.FC = () => <Spinner size={SpinnerSize.medium} style={{ height: 32 }} />

const rootLinks = [
    { name: 'Køber dashboard', url: '/', key: 'key0', isExpanded: true },
    { name: 'Salgsvurdering', url: '/seller-leads/', key: 'key1', isExpanded: true },
    { name: 'Mælger profil', url: '/edit-personal-profile/', key: 'key2' },
    { name: 'Forretnings profil', url: '/edit-company-profile/', key: 'key3' },
    { name: 'Bruger', url: '/edit-company-access/', key: 'key4' },
    { name: 'Fakturering', url: '/edit-products/', key: 'key5' },
    { name: 'Support', url: 'https://venando.dk/kontakt', key: 'key6', target: '_blank' },
]

export const AppBody: React.FC<{ children: React.ReactNode, loading?: boolean }> = ({ children, loading }) => {

    return (
        <RootBody loading={loading}>
            <Stack style={{ padding: SmallGap }}>
                <NavBar />
                <Stack horizontal={!isMobile} tokens={{ childrenGap: LargeGap }}>
                    <Stack tokens={{ childrenGap: LargeGap }}>
                        <Nav groups={[
                            {
                                links: isMobile ? [{
                                    name: 'Menu',
                                    url: '',
                                    links: rootLinks
                                }] : rootLinks
                            }]} />
                    </Stack>
                    <Stack style={{ width: '100%', paddingBottom: LargeGap }} tokens={{ childrenGap: LargeGap }}>
                        {children}
                    </Stack>
                </Stack>
            </Stack>
        </RootBody>
    )
}

export const RootBody: React.FC<{ children: React.ReactNode, loading?: boolean }> = ({ children, loading }) => {
    const navigate = useNavigate()
    const [showRelaodButton, setShowReloadButton] = React.useState(false)

    React.useEffect(() => {
        setInterval(() => { setShowReloadButton(true) }, 4000)
    }, [])

    return (
        <Root>
            {loading ? (
                <Stack style={{ maxWidth: 1100, marginLeft: 'auto', marginRight: 'auto', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                    <img alt="venando logo" src={VenandoLogo}
                        style={{ width: 140, paddingBottom: 20 }} />
                    <p>Arbejder..</p>
                    {showRelaodButton && <PrimaryButton text='Genindlæs siden' onClick={() => navigate(0)} />}
                </Stack>
            ) : (
                <Stack style={{ maxWidth: "100vw", marginLeft: 'auto', marginRight: 'auto' }}>
                    {children}
                </Stack>
            )}
        </Root>
    )
}

