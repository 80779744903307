import React, { Fragment } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import Dashboard from './pages/DashboardPage';
import Login from './pages/LoginPage';
import ConfirmLogin from './pages/ConfirmLoginPage';
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import { createTheme } from '@fluentui/theme';
import { ThemeProvider } from '@fluentui/react';
import { PrimaryColor } from './utils/globals';
import SignupBusinessPage from './pages/SignupBusinessPage';
import SignupUserPage from './pages/SignupUserPage';
import LogoutPage from './pages/LogoutPage';
import EditProfilePage from './pages/EditProfilePage';
import EditCompanyProfilePage from './pages/EditCompanyProfilePage';
import EditCompanyProductPage from './pages/EditCompanyProductPage';
import SelectProductPage from './pages/SelectProductPage';
import ValidateSubscriptionPage from './pages/ValidateSubscriptionPage';
import SellersPage from './pages/SellersPage';
import ErrorPage from './pages/ErrorPage';
import EditCompanyAccessPage from './pages/EditCompanyAccessPage';
import PendingApprovalPage from './pages/PendingApprovalPage';
import MissingCompanyProductPage from './pages/MissingCompanyProductPage';

initializeIcons()

const appTheme = createTheme({
  defaultFontStyle: { fontFamily: 'sans-serif', fontWeight: 'regular' },
  palette: {
    themePrimary: PrimaryColor,
    themeLighterAlt: '#f5fafd',
    themeLighter: '#d6ecf6',
    themeLight: '#b4dcee',
    themeTertiary: '#72bbdd',
    themeSecondary: '#3b9fce',
    themeDarkAlt: '#2285b3',
    themeDark: '#1d7097',
    themeDarker: '#15536f',
    neutralLighterAlt: '#faf9f8',
    neutralLighter: '#f3f2f1',
    neutralLight: '#edebe9',
    neutralQuaternaryAlt: '#e1dfdd',
    neutralQuaternary: '#d0d0d0',
    neutralTertiaryAlt: '#c8c6c4',
    neutralTertiary: '#bfc0c9',
    neutralSecondary: '#848694',
    neutralSecondaryAlt: '#848694',
    neutralPrimaryAlt: '#525562',
    neutralPrimary: '#3e404c',
    neutralDark: '#30313a',
    black: '#23242b',
    white: '#ffffff',
  }
});

const Router: React.FC = () => (
  <Routes>
    <Fragment>
      <Route path='/' element={<Dashboard />} />
      <Route path='/seller-leads' element={<SellersPage />} />
      <Route path='/login' element={<Login />} />
      <Route path='/business-signup' element={<SignupBusinessPage />} />
      <Route path='/user-signup' element={<SignupUserPage />} />
      <Route path='/confirmlogin/:sessionId' element={<ConfirmLogin />} />
      <Route path='/logout' element={<LogoutPage />} />
      <Route path='/edit-personal-profile' element={<EditProfilePage />} />
      <Route path='/edit-company-profile' element={<EditCompanyProfilePage />} />
      <Route path='/edit-company-access' element={<EditCompanyAccessPage />} />
      <Route path='/edit-products' element={<EditCompanyProductPage />} />
      <Route path='/missing-payment' element={<MissingCompanyProductPage />} />
      <Route path='/select-product' element={<SelectProductPage />} />
      <Route path='/validate-subscription' element={<ValidateSubscriptionPage/>}/>
      <Route path='/error' element={<ErrorPage />} />
      <Route path='/pending-approval' element={<PendingApprovalPage />} />
    </Fragment>
  </Routes>
)


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={appTheme}>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>
);

reportWebVitals();
