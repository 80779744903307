import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import hooks from '../utils/hooks'
import { PrimaryButton, Spinner, SpinnerSize, Stack } from '@fluentui/react'
import { AppBody } from '../components/wrappers'
import { LargeGap } from '../utils/globals'


const MissingCompanyProductPage: React.FC = () => {
    const navigate = useNavigate()
    const [url, setUrl] = React.useState<string>()
    const [subscriptionNotAvailable, setSubscriptionNotAvailable] = React.useState<boolean>(false)

    React.useEffect(() => {
        (async () => {
            try {
                const result = await hooks.getBillingSessionURL()
                if (result) {
                    if(typeof result  === "string") setUrl(result)
                    else setSubscriptionNotAvailable(true)
                } else navigate('/error')
            } catch (error) {
                navigate('/error')
            }
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <AppBody>
            <Stack horizontal>
                <Stack style={{width:'100%'}} tokens={{ childrenGap: LargeGap }}>
                    <Stack.Item><h2>{subscriptionNotAvailable? "Du har ikke privilegier til at oprette et abonnement":"Vi mangler dine betalingsoplysninger"}</h2></Stack.Item>
                    {!subscriptionNotAvailable && url && <Stack.Item align='center'><PrimaryButton href={url} text='Færdiggør din profil'/></Stack.Item>}
                    {!subscriptionNotAvailable && <Stack.Item>{!url ? <Spinner size={SpinnerSize.medium} style={{ height: 32 }} /> : <span><a href={url}>Tryk her</a> for at tilføje dit dankort.</span>}</Stack.Item>}
                    <Stack.Item>
                        <span>Hvis du er logget ind på den forkerte konti kan du logge ud igen her: <a href='/logout'>Log ud</a></span>
                    </Stack.Item>
                    <Stack.Item>
                        <span>Du skal også være velkommen til at kontakte os via vores kontaktformular: </span>
                        <a href={'https://www.venando.dk/kontakt'}>Kontakt os</a>
                    </Stack.Item>
                </Stack>
            </Stack>
        </AppBody>
    )
}

export default MissingCompanyProductPage