import * as React from 'react'
import { DetailsList, SelectionMode, Stack, StackItem, TextField, Toggle } from '@fluentui/react'
import { ErrorContext, LoginBody } from '../components/wrappers'
import { sleep, SmallGap } from '../utils/globals'
import { NiceNumberWithCurrency } from '../utils/formatters'
import { ButtonWithLoading } from '../components/Button'
import { Plans } from '../utils/variables'
import hooks from '../utils/hooks'
import { useNavigate } from 'react-router-dom'
import { VenandoAgencyPrice } from '@venando/common'


const DiscountCodeForm: React.FC<{ updateProduct(code?: string): Promise<void> }> = ({ updateProduct }) => {
    const [open, setOpen] = React.useState(false)
    const [code, setCode] = React.useState<string | undefined>()

    const useDiscountCode = async () => {
        await updateProduct(code)
    }
    return (
        <Stack style={{marginBottom: 22}}>
            <Toggle label="Jeg har en rabatkode" checked={open} inlineLabel onChange={() => setOpen(!open)} />
            <Stack horizontal verticalAlign='end' hidden={!open}>
                <TextField hidden={!open} disabled={!open} onChange={(_e, val) => { setCode(val) }} value={code} />
                {open && <ButtonWithLoading  disabled={!open} onClick={useDiscountCode} text='Brug rabatkode' />}
            </Stack>
        </Stack>
    )
}


const OrderLineTables: React.FC<{ product?: VenandoAgencyPrice }> = ({ product }) => {

    if (!product) return <></>
    const sum = product.amount

    return (
        <DetailsList items={[
            { text: <b>{product.name}</b>, price: <b>{NiceNumberWithCurrency(product.amount * 0.8)}</b> },
            { text: 'Moms', price: NiceNumberWithCurrency(sum - (sum * 0.8)) },
            { text: 'Total', price: NiceNumberWithCurrency(sum) }
        ]} selectionMode={SelectionMode.none} columns={[
            { key: 'productName', name: 'Månedlig betaling', fieldName: 'text', minWidth: 100, maxWidth: 300, isResizable: false },
            { key: 'price', name: '', fieldName: 'price', minWidth: 100, maxWidth: 200, isResizable: false },
        ]} />
    )
}


const SelectProductPage: React.FC = () => {
    const navigate = useNavigate()
    const { showErrorMessage } = React.useContext(ErrorContext)
    const [product, setProduct] = React.useState<VenandoAgencyPrice>()

    const getProduct = async (coupon?: string) => {
        const productResult = await hooks.getProducts(coupon)
        setProduct(productResult)
    }

    const checkProduct = async (coupon?: string) => {
        const result = await hooks.validateSubscription()
        if (result === true) navigate('/')
        else await getProduct(coupon)
    }

    React.useEffect(() => {
        checkProduct()
        //eslint-disable-next-line
    }, [])

    const onComplete = async () => {
        const success = await hooks.selectAgencyPlan(Plans.monthly, [])
        if (success) window.location.assign(success)
        else showErrorMessage('Der skete en fejl')
        //Make sure button is hidden
        await sleep(1000)
    }

    return (
        <LoginBody>
            <Stack tokens={{ childrenGap: SmallGap }}>
                <StackItem><h1>Vælg produkt</h1></StackItem>
                <OrderLineTables product={product} />
                <StackItem>
                    <ButtonWithLoading onClick={onComplete} text='Til betaling' />
                </StackItem>

                <StackItem>
                    <i style={{fontSize: 11}}>Har du en rabatkode? Du kan tilføje den på næste side under <b>Tilføj kampagnekode</b></i>
                </StackItem>
            </Stack>
        </LoginBody>
    )
}

export default SelectProductPage