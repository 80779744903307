import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { LoginBody } from '../components/wrappers'
import { Spinner, SpinnerSize, Stack } from '@fluentui/react'
import { SmallGap } from '../utils/globals'
import _ from 'lodash'
import hooks from '../utils/hooks'


const LogoutPage: React.FC = () => {
    const navigate = useNavigate()

    const handleLogut = () => {
        console.log(document.cookie)
        hooks.logout()
        _.delay(() => {
            navigate('/login')
          }, 300);
    }

    React.useEffect(() => {
        handleLogut()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <LoginBody>
            <Stack tokens={{ childrenGap: SmallGap }}>
                <Spinner size={SpinnerSize.medium} style={{ height: 32 }} />
            </Stack>
        </LoginBody>
    )
}

export default LogoutPage