import * as React from 'react'
import { Stack } from '@fluentui/react'
import { LargeGap, SmallGap, isMobile } from '../utils/globals'
import hooks from '../utils/hooks';
import { UpdatableAgentProperties, Agent, Agency } from '@venando/common';
import { FileInput, FileTypes, InputWithLabel, InputWrapper, MultilineInputWithLabel } from './Fields';
import { ButtonWithLoading } from './Button';

const defaultProfileImage = 'https://uploads-ssl.webflow.com/64f8671b332495b34b825240/658da81a61f9907c2dc6e671_profile-image.png'


const EditAgent: React.FC<{ agent?: Agent, agency?: Agency, updateAgent: (updated: Agent) => void }> = ({ agent, updateAgent, agency }) => {
    const [loading, setLoading] = React.useState(true)
    const [name, setName] = React.useState<string | undefined>()
    const [description, setDescription] = React.useState<string | undefined>()
    const [profileImage, setProfileImage] = React.useState<string | undefined>()
    const [phone, setPhone] = React.useState<string | undefined>(agent?.phone)

    const handleUpdate = async () => {
        setLoading(true)
        const props: UpdatableAgentProperties = { agentProfileDescription: description, agentProfileName: name, agentProfileImage: profileImage, phone: phone || '' }
        const updated = await hooks.updateAgent(props)
        if (updated) updateAgent(updated)
        setLoading(false)
    }

    React.useEffect(() => {
        if (agent) {
            setLoading(false)
            setName(agent.agentProfileName)
            setDescription(agent.agentProfileDescription)
            setProfileImage(agent.agentProfileImage)
        }
    }, [agent])

    const handleDescriptionChange = (updatedDescription?: string) => {
        if (updatedDescription && updatedDescription.length < 140) setDescription(updatedDescription)
        else setDescription(updatedDescription?.slice(0, 140))
    }

    return (
        <Stack tokens={{ childrenGap: LargeGap }}>
            <Stack horizontal={!isMobile} tokens={{ childrenGap: LargeGap }}>
                <Stack.Item style={{ minWidth: 300 }}>
                    <InputWithLabel label='Din email' setValue={() => { }} value={agent?.email} loading={loading || !agent} disable />
                    <InputWithLabel label='Dit telefonnummer' setValue={setPhone} value={agent?.phone} loading={loading || !agent} />
                    <InputWithLabel label='Dit navn' setValue={setName} value={name} loading={loading || !agent} />
                    <MultilineInputWithLabel label={'Beskrivelse af dig (ejendomsmægler, MDE, trainee eller andet) (' + (description?.length || 0) + '/140)'} setValue={handleDescriptionChange} value={description} loading={loading || !agent} />
                    <InputWrapper label='Profilbillede (1:1)' loading={loading}>
                        <FileInput setter={setProfileImage} loading={loading} value={profileImage} allowedFileTypes={FileTypes.imageFileTypes} />
                    </InputWrapper>
                </Stack.Item>
                <Stack.Item>
                    <div style={{ width: 250, borderWidth: 1, borderStyle: 'solid', borderColor: "#ebebef", padding: LargeGap }}>
                        <Stack tokens={{ childrenGap: SmallGap }}>
                            <Stack horizontal tokens={{ childrenGap: SmallGap }}>
                                <div style={{
                                    width: 50, height: 50, borderRadius: 50,
                                    backgroundImage: 'url(' + (profileImage || defaultProfileImage) + ')',
                                    backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'
                                }} />
                                <h5>{name}</h5>
                            </Stack>
                            <Stack horizontal><div style={{ backgroundColor: '#f3f3fc', color: "#2693c7", padding: "6px 12px" }}>{agency?.companyProfileName}</div></Stack>
                            <p style={{ color: "#84889a", fontSize: 12 }}>{description}</p>
                        </Stack>
                    </div>
                </Stack.Item>
            </Stack>
            <Stack horizontal> <ButtonWithLoading text='Gem ændringer' onClick={handleUpdate} /></Stack>
        </Stack>
    )

}

export default EditAgent