import * as React from 'react'
import { DefaultButton, Stack } from '@fluentui/react'
import { RootBody } from '../components/wrappers'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LargeGap, SmallGap } from '../utils/globals';
import NavBar from '../components/NavComponents';


const ErrorPage: React.FC = () => {
    const navigate = useNavigate()
    let [searchParams] = useSearchParams();
    const [errorMessage, setErrorMessage] = React.useState<string>()


    const getErrorMessage = () => {
        try {
            const errorMessage = (searchParams.entries().next()).value[0]
            if (!errorMessage) return
            const result = JSON.parse(errorMessage)
            if(result.error) setErrorMessage(result.error)

        } catch (error) {
            console.log(error)
        }
    }

    React.useEffect(() => {
        getErrorMessage()
        // eslint-disable-next-line
    }, [searchParams])

    return (
        <RootBody>
            <Stack style={{ padding: SmallGap }}>
                <NavBar />
                <Stack tokens={{ childrenGap: LargeGap }}>
                    <Stack.Item><h2>Der skete en fejl</h2></Stack.Item>
                    {errorMessage && <Stack.Item><b>{errorMessage}</b></Stack.Item>}
                    <p style={{ padding: LargeGap, backgroundColor: "#4c4c4c", color: "#ffffff", borderRadius: SmallGap }}>{searchParams}</p>
                    <Stack.Item>
                        <span>Du kan prøve at logge ud og ind igen: </span>
                        <DefaultButton onClick={() => navigate('/logout')} text='Log ud' />
                    </Stack.Item>
                    <Stack.Item>
                        <span>Alternativt kan du sende din fejlbesked via følgende link: </span>
                        <a href={'mailto:web@venando.dk?subject=Fejl på Venando mæglerplatform&body=TILFØJ BESKRIVELSE HER:%0D%0A%0D%0A%0D%0A%0D%0A%0D%0AData nødvendigt for fejlfinding: ' + searchParams}>Raporter fejl til Supporten</a>
                    </Stack.Item>
                </Stack>
            </Stack>

        </RootBody>
    )
}

export default ErrorPage