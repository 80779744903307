import * as React from 'react';
import { SearchSetting, SearchSettingSortingOptions } from '@venando/common';
import { Callout, DefaultButton, Dropdown, FontIcon, IDropdownOption, ITextFieldProps, Stack, TextField } from '@fluentui/react';
import { LargeGap, SmallGap } from '../utils/globals';
import { PropertyTypes } from '../utils/property-types';
import { CURRENCY_SUFFIX, NiceNumber } from '../utils/formatters';
import zipcodeLib from '../utils/zipcode-lib';
import { ButtonTypes, ButtonWithLoading } from './Button';


const onNumberValueChange = (setter: (val?: number) => SearchSetting, value?: string,) => {
    const numberValue = Number(value)
    if (!isNaN(numberValue)) return setter(numberValue)
    return setter(undefined)
}

const NiceNumberInput: React.FC<ITextFieldProps> = (props) => {

    const [value, setValue] = React.useState<number>(Number(props.value || props.defaultValue || 0))

    const localOnChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        const modifiedValue = Number((newValue || '').split('.').join(''))
        if (isNaN(modifiedValue)) return

        setValue(modifiedValue)
        if (props.onChange) props.onChange(event, modifiedValue.toString())
    }

    return <TextField {...props} onChange={localOnChange} value={(!value) ? '' : NiceNumber(value)} />
}

type HeaderProps = { searchSetting: SearchSetting, setSearchSetting(value: SearchSetting): void }

export const NameHeader: React.FC<HeaderProps> = ({ searchSetting, setSearchSetting }) => (
    <Stack>
        <Stack horizontal tokens={{ childrenGap: SmallGap }}>
            <TextField style={{ width: 180 }} placeholder='Navn' onChange={(_e, val) => setSearchSetting({ ...searchSetting, name: val })} defaultValue={searchSetting.name?.toString()} />
        </Stack>
    </Stack>
)

export const AreaHeader: React.FC<HeaderProps & { areas: string[] }> = ({ searchSetting, setSearchSetting, areas }) => {

    const areaOptions = areas.reduce((acc, zipId) => [...acc, { key: zipId, text: zipcodeLib.getZipDisplayName(zipId) }], [{ key: "all", text: "Alle områder" }])

    const onAreaChange = (value?: IDropdownOption<any>) => {
        const option = value?.key?.toString() || undefined
        if (option === "all") setSearchSetting({ ...searchSetting, areaId: undefined })
        else if (option) setSearchSetting({ ...searchSetting, areaId: option })
        else setSearchSetting({ ...searchSetting, areaId: undefined })
    }

    return (
        <Stack.Item>
            <Dropdown placeholder='Område' style={{ width: 200 }}
                onChange={(_, option) => onAreaChange(option)}
                options={areaOptions} />
        </Stack.Item>
    )
}

export const PropertyTypeHeader: React.FC<HeaderProps> = ({ searchSetting, setSearchSetting }) => {

    const propertyTypeOptions = [
        { key: "all", text: "Alle ejendomstyper" },
        ...PropertyTypes.map((i) => ({ key: i.id, text: i.name }))
    ]

    const onPropertyTypeChange = (value?: IDropdownOption<any>) => {
        const option = value?.key?.toString() || undefined
        if (option === "all") setSearchSetting({ ...searchSetting, propertyType: undefined })
        else if (option) setSearchSetting({ ...searchSetting, propertyType: option })
        else setSearchSetting({ ...searchSetting, propertyType: undefined })
    }


    return (
        <Stack.Item><Dropdown placeholder='Vælg ejendomstypen' style={{ width: 150 }}
            onChange={(_, option) => onPropertyTypeChange(option)}
            options={propertyTypeOptions} />
        </Stack.Item>
    )
}

const shortenNumber = (val: number) => {
    if (val >= 1000000) return Math.round(val / 1000000) + " mio"
    if (val >= 1000) return Math.round(val / 1000) + "k"
    return NiceNumber(val)
}

export const BudgetHeader: React.FC<HeaderProps> = ({ searchSetting, setSearchSetting }) => {

    const [budgetVisible, setBudgetVisible] = React.useState(false)

    const invalidAmount = !!searchSetting.maxPrice && !!searchSetting.minPrice && searchSetting.minPrice < searchSetting.maxPrice


    const reset = async () => {
        setSearchSetting({ ...searchSetting, minPrice: undefined, maxPrice: undefined })
        setBudgetVisible(false)
    }

    const invalidAmountErrorMessage = "Ugyldigt interval"

    const getBudgetLabel = () => {
        const { maxPrice, minPrice, } = searchSetting

        if (maxPrice && minPrice) return shortenNumber(maxPrice) + ' - ' + shortenNumber(minPrice) + " " + CURRENCY_SUFFIX
        if (minPrice) return "Til: " + shortenNumber(minPrice) + " " + CURRENCY_SUFFIX
        if (maxPrice) return "Fra: " + shortenNumber(maxPrice) + " " + CURRENCY_SUFFIX

        return "Budget"
    }
    const title = budgetVisible ? "Færdig" : getBudgetLabel()

    return (
        <Stack tokens={{ childrenGap: SmallGap }}>
            <DefaultButton style={{ width: 150 }} iconProps={{ iconName: budgetVisible ? "Cancel" : "Filter" }} id={BudgetHeader.name} onClick={() => setBudgetVisible(!budgetVisible)}>{title}</DefaultButton>
            {budgetVisible &&
                <Callout role="dialog" target={`#${BudgetHeader.name}`} style={{ padding: LargeGap }}>
                    <Stack tokens={{ childrenGap: LargeGap }}>
                        <Stack horizontal tokens={{ childrenGap: SmallGap }}>
                            <NiceNumberInput onChange={(_e, val) => setSearchSetting(onNumberValueChange((value) => ({ ...searchSetting, maxPrice: value }), val))} defaultValue={searchSetting.maxPrice?.toString()} prefix='Fra' suffix={CURRENCY_SUFFIX} />
                            <NiceNumberInput onChange={(_e, val) => setSearchSetting(onNumberValueChange((value) => ({ ...searchSetting, minPrice: value }), val))} defaultValue={searchSetting.minPrice?.toString()} prefix='Til' suffix={CURRENCY_SUFFIX} />
                        </Stack>
                        <Stack horizontal horizontalAlign='space-between'>
                            <Stack.Item><b style={{ color: 'red' }}>{invalidAmount && invalidAmountErrorMessage}</b></Stack.Item>
                            <Stack horizontal tokens={{ childrenGap: SmallGap }}>
                                <ButtonWithLoading disabled={invalidAmount} buttonType={ButtonTypes.secondary} text='Annuller' onClick={reset} />
                                <ButtonWithLoading disabled={invalidAmount} text='Færdig' onClick={async () => setBudgetVisible(false)} />
                            </Stack>
                        </Stack>
                    </Stack>
                </Callout>
            }
        </Stack>
    )
}


export const SquareMetersHeader: React.FC<HeaderProps> = ({ searchSetting, setSearchSetting }) => {

    const [visible, setVisible] = React.useState(false)

    const invalidAmount = !!searchSetting.maxSquareMeters && !!searchSetting.minSquareMeters && searchSetting.minSquareMeters > searchSetting.maxSquareMeters

    const reset = async () => {
        setSearchSetting({ ...searchSetting, maxSquareMeters: undefined, minSquareMeters: undefined })
        setVisible(false)
    }

    const invalidAmountErrorMessage = "Ugyldigt interval"

    const getLabel = () => {
        const { minSquareMeters, maxSquareMeters } = searchSetting

        if (minSquareMeters && maxSquareMeters) return minSquareMeters + " " + maxSquareMeters + " m2"
        if (minSquareMeters) return minSquareMeters + " m2"
        if (maxSquareMeters) return maxSquareMeters + " m2"

        return "Størrelse"
    }

    const title = visible ? "Færdig" : getLabel()

    return (
        <Stack tokens={{ childrenGap: SmallGap }}>
            <DefaultButton style={{ width: 150 }} iconProps={{ iconName: visible ? "Cancel" : "Filter" }} id={SquareMetersHeader.name} onClick={() => setVisible(!visible)}>{title}</DefaultButton>
            {visible &&
                <Callout role="dialog" target={`#${SquareMetersHeader.name}`} style={{ padding: LargeGap }}>
                    <Stack tokens={{ childrenGap: LargeGap }}>
                        <Stack horizontal tokens={{ childrenGap: SmallGap }}>
                            <NiceNumberInput onChange={(_e, val) => setSearchSetting(onNumberValueChange((value) => ({ ...searchSetting, minSquareMeters: value }), val))} defaultValue={searchSetting.minSquareMeters?.toString()} prefix='Min' suffix='m2' />
                            <NiceNumberInput onChange={(_e, val) => setSearchSetting(onNumberValueChange((value) => ({ ...searchSetting, maxSquareMeters: value }), val))} defaultValue={searchSetting.maxSquareMeters?.toString()} prefix='Max' suffix='m2' />
                        </Stack>
                        <Stack horizontal horizontalAlign='space-between'>
                            <Stack.Item><b style={{ color: 'red' }}>{invalidAmount && invalidAmountErrorMessage}</b></Stack.Item>
                            <Stack horizontal tokens={{ childrenGap: SmallGap }}>
                                <ButtonWithLoading disabled={invalidAmount} buttonType={ButtonTypes.secondary} text='Annuller' onClick={reset} />
                                <ButtonWithLoading disabled={invalidAmount} text='Færdig' onClick={async () => setVisible(false)} />
                            </Stack>
                        </Stack>
                    </Stack>
                </Callout>
            }
        </Stack>
    )
}



export const RoomsHeader: React.FC<HeaderProps> = ({ searchSetting, setSearchSetting }) => {
    const roomOptionList = [{ key: 1, text: "1 værelse" }, { key: 2, text: "2 værelser" }, { key: 3, text: "3 værelser" }, { key: 4, text: "+4 værelser" },]


    const onChange = (value?: IDropdownOption<any>) => {
        if (!value || isNaN(Number(value.key))) return
        const key = Number(value.key)
        const existingRoomsList = searchSetting.rooms || []
        if (value.selected) setSearchSetting({ ...searchSetting, rooms: [...existingRoomsList, key] })
        else setSearchSetting({ ...searchSetting, rooms: existingRoomsList.filter((i) => i !== key) })
    }

    return (
        <Stack.Item>
            <Dropdown multiSelect placeholder='Værelser' style={{ width: 150 }}
                onChange={(_, option) => onChange(option)}
                options={roomOptionList} />
        </Stack.Item>
    )
}


export const SortHeader: React.FC<HeaderProps> = ({ searchSetting, setSearchSetting }) => {
    const options: IDropdownOption<SearchSettingSortingOptions>[] = [
        { key: 'created-date', text: "Oprettelsesdato" },
        { key: 'price-asc', text: "Pris faldende" },
        { key: 'price-desc', text: "Pris sigende" }]


    const onChange = (value?: IDropdownOption<SearchSettingSortingOptions>) => {
        if (!value) return
        if (value.key) setSearchSetting({ ...searchSetting, sorting: value.key as SearchSettingSortingOptions  })
    }

    return (
        <Stack horizontal tokens={{ childrenGap: SmallGap }} verticalAlign='center'>
            <Stack.Item>
                <span>Sorter</span>
            </Stack.Item>
            <Stack.Item>
                <FontIcon iconName="Sort" />
            </Stack.Item>
            <Stack.Item>
                <Dropdown placeholder='Sorter' style={{ width: 150 }}
                    defaultSelectedKey={searchSetting.sorting}
                    onChange={(_, option?: IDropdownOption<SearchSettingSortingOptions>) => onChange(option)}
                    options={options} />
            </Stack.Item>
        </Stack>
    )
}