import * as React from 'react'
import { Stack, StackItem } from '@fluentui/react'
import { CustomSpinner, LoginBody } from '../components/wrappers'
import { SmallGap } from '../utils/globals'
import hooks from '../utils/hooks'
import { useNavigate } from 'react-router-dom'


const ValidateSubscriptionPage: React.FC = () => {
    const [loading, setLoading] = React.useState(false)
    const navigate = useNavigate()

    const getClientSecret = async () => {
        setLoading(true)
        const result = await hooks.validateSubscription()
        if(result === true) navigate('/')
        else navigate('/missing-payment')
        setLoading(false)
    }

    React.useEffect(() => {
        getClientSecret()
        //eslint-disable-next-line
    }, [])


    return (
        <LoginBody>
            <Stack tokens={{ childrenGap: SmallGap }}>
                <StackItem><h1>Vi bekræfter dine oplysninger</h1></StackItem>

                {loading && <CustomSpinner />}

            </Stack>
        </LoginBody>
    )
}

export default ValidateSubscriptionPage