

const zipMap = [
    { "id": "1000", "name": "1000-1473 København K" },
    { "id": "1500", "name": "1500-1700 København V" },
    { "id": "2000", "name": "1800-2000 Frederiksberg" },
    { "id": "2100", "name": "2100 København Ø" },
    { "id": "2200", "name": "2200 København N" },
    { "id": "2300", "name": "2300 København S" },
    { "id": "2400", "name": "2400 København NV" },
    { "id": "2450", "name": "2450 København SV" },
    { "id": "2500", "name": "2500 Valby" },
    { "id": "2600", "name": "2600 Glostrup" },
    { "id": "2605", "name": "2605 Brøndby" },
    { "id": "2610", "name": "2610 Rødovre" },
    { "id": "2625", "name": "2625 Vallensbæk" },
    { "id": "2630", "name": "2630 Taastrup" },
    { "id": "2635", "name": "2635 Ishøj" },
    { "id": "2640", "name": "2640 Hedehusene" },
    { "id": "2650", "name": "2650 Hvidovre" },
    { "id": "2660", "name": "2660 Brøndby Strand" },
    { "id": "2665", "name": "2665 Vallensbæk Strand" },
    { "id": "2670", "name": "2670 Greve" },
    { "id": "2680", "name": "2680 Solrød" },
    { "id": "2690", "name": "2690 Karlslunde" },
    { "id": "2700", "name": "2700 Brønshøj" },
    { "id": "2720", "name": "2720 Vanløse" },
    { "id": "2730", "name": "2730 Herlev" },
    { "id": "2740", "name": "2740 Skovlunde" },
    { "id": "2750", "name": "2750 Ballerup" },
    { "id": "2760", "name": "2760 Måløv" },
    { "id": "2765", "name": "2765 Smørum" },
    { "id": "2770", "name": "2770 Kastrup" },
    { "id": "2791", "name": "2791 Dragør" },
    { "id": "2800", "name": "2800 Kongens Lyngby" },
    { "id": "2820", "name": "2820 Gentofte" },
    { "id": "2830", "name": "2830 Virum" },
    { "id": "2840", "name": "2840 Holte" },
    { "id": "2850", "name": "2850 Nærum" },
    { "id": "2860", "name": "2860 Søborg" },
    { "id": "2880", "name": "2880 Bagsværd" },
    { "id": "2900", "name": "2900 Hellerup" },
    { "id": "2920", "name": "2920 Charlottenlund" },
    { "id": "2930", "name": "2930 Klampenborg" },
    { "id": "2942", "name": "2942 Skodsborg" },
    { "id": "2950", "name": "2950 Vedbæk" },
    { "id": "2960", "name": "2960 Rungsted Kyst" },
    { "id": "2970", "name": "2970 Hørsholm" },
    { "id": "2980", "name": "2980 Kokkedal" },
    { "id": "2990", "name": "2990 Nivå" },
    { "id": "3000", "name": "3000 Helsingør" },
    { "id": "3050", "name": "3050 Humlebæk" },
    { "id": "3060", "name": "3060 Espergærde" },
    { "id": "3070", "name": "3070 Snekkersten" },
    { "id": "3080", "name": "3080 Tikøb" },
    { "id": "3100", "name": "3100 Hornbæk" },
    { "id": "3120", "name": "3120 Dronningmølle" },
    { "id": "3140", "name": "3140 Ålsgårde" },
    { "id": "3150", "name": "3150 Hellebæk" },
    { "id": "3200", "name": "3200 Helsinge" },
    { "id": "3210", "name": "3210 Vejby" },
    { "id": "3220", "name": "3220 Tisvildeleje" },
    { "id": "3230", "name": "3230 Græsted" },
    { "id": "3250", "name": "3250 Gilleleje" },
    { "id": "3300", "name": "3300 Frederiksværk" },
    { "id": "3310", "name": "3310 Ølsted" },
    { "id": "3320", "name": "3320 Skævinge" },
    { "id": "3330", "name": "3330 Gørløse" },
    { "id": "3360", "name": "3360 Liseleje" },
    { "id": "3370", "name": "3370 Melby" },
    { "id": "3390", "name": "3390 Hundested" },
    { "id": "3400", "name": "3400 Hillerød" },
    { "id": "3460", "name": "3460 Birkerød" },
    { "id": "3480", "name": "3480 Fredensborg" },
    { "id": "3490", "name": "3490 Kvistgård" },
    { "id": "3500", "name": "3500 Værløse" },
    { "id": "3520", "name": "3520 Farum" },
    { "id": "3540", "name": "3540 Lynge" },
    { "id": "3550", "name": "3550 Slangerup" },
    { "id": "3600", "name": "3600 Frederikssund" },
    { "id": "3630", "name": "3630 Jægerspris" },
    { "id": "3650", "name": "3650 Ølstykke" },
    { "id": "3660", "name": "3660 Stenløse" },
    { "id": "3670", "name": "3670 Veksø Sjælland" },
    { "id": "3700", "name": "3700 Rønne" },
    { "id": "3720", "name": "3720 Aakirkeby" },
    { "id": "3730", "name": "3730 Nexø" },
    { "id": "3740", "name": "3740 Svaneke" },
    { "id": "3751", "name": "3751 Østermarie" },
    { "id": "3760", "name": "3760 Gudhjem" },
    { "id": "3770", "name": "3770 Allinge" },
    { "id": "3782", "name": "3782 Klemensker" },
    { "id": "3790", "name": "3790 Hasle" },
    { "id": "4000", "name": "4000 Roskilde" },
    { "id": "4040", "name": "4040 Jyllinge" },
    { "id": "4050", "name": "4050 Skibby" },
    { "id": "4060", "name": "4060 Kirke Såby" },
    { "id": "4070", "name": "4070 Kirke Hyllinge" },
    { "id": "4100", "name": "4100 Ringsted" },
    { "id": "4130", "name": "4130 Viby Sjælland" },
    { "id": "4140", "name": "4140 Borup" },
    { "id": "4160", "name": "4160 Herlufmagle" },
    { "id": "4171", "name": "4171 Glumsø" },
    { "id": "4173", "name": "4173 Fjenneslev" },
    { "id": "4174", "name": "4174 Jystrup" },
    { "id": "4180", "name": "4180 Sorø" },
    { "id": "4190", "name": "4190 Munke Bjergby" },
    { "id": "4200", "name": "4200 Slagelse" },
    { "id": "4220", "name": "4220 Korsør" },
    { "id": "4230", "name": "4230 Skælskør" },
    { "id": "4241", "name": "4241 Vemmelev" },
    { "id": "4242", "name": "4242 Boeslunde" },
    { "id": "4243", "name": "4243 Rude" },
    { "id": "4250", "name": "4250 Fuglebjerg" },
    { "id": "4261", "name": "4261 Dalmose" },
    { "id": "4262", "name": "4262 Sandved" },
    { "id": "4270", "name": "4270 Høng" },
    { "id": "4281", "name": "4281 Gørlev" },
    { "id": "4291", "name": "4291 Ruds Vedby" },
    { "id": "4293", "name": "4293 Dianalund" },
    { "id": "4295", "name": "4295 Stenlille" },
    { "id": "4296", "name": "4296 Nyrup" },
    { "id": "4300", "name": "4300 Holbæk" },
    { "id": "4320", "name": "4320 Lejre" },
    { "id": "4330", "name": "4330 Hvalsø" },
    { "id": "4340", "name": "4340 Tølløse" },
    { "id": "4350", "name": "4350 Ugerløse" },
    { "id": "4360", "name": "4360 Kirke Eskilstrup" },
    { "id": "4370", "name": "4370 Store Merløse" },
    { "id": "4390", "name": "4390 Vipperød" },
    { "id": "4400", "name": "4400 Kalundborg" },
    { "id": "4420", "name": "4420 Regstrup" },
    { "id": "4440", "name": "4440 Mørkøv" },
    { "id": "4450", "name": "4450 Jyderup" },
    { "id": "4460", "name": "4460 Snertinge" },
    { "id": "4470", "name": "4470 Svebølle" },
    { "id": "4480", "name": "4480 Store Fuglede" },
    { "id": "4490", "name": "4490 Jerslev" },
    { "id": "4500", "name": "4500 Nykøbing Sj" },
    { "id": "4520", "name": "4520 Svinninge" },
    { "id": "4532", "name": "4532 Gislinge" },
    { "id": "4534", "name": "4534 Hørve" },
    { "id": "4540", "name": "4540 Fårevejle" },
    { "id": "4550", "name": "4550 Asnæs" },
    { "id": "4560", "name": "4560 Vig" },
    { "id": "4571", "name": "4571 Grevinge" },
    { "id": "4572", "name": "4572 Nørre Asmindrup" },
    { "id": "4573", "name": "4573 Højby" },
    { "id": "4581", "name": "4581 Rørvig" },
    { "id": "4583", "name": "4583 Sjællands Odde" },
    { "id": "4591", "name": "4591 Føllenslev" },
    { "id": "4592", "name": "4592 Sejerø" },
    { "id": "4593", "name": "4593 Eskebjerg" },
    { "id": "4600", "name": "4600 Køge" },
    { "id": "4621", "name": "4621 Gadstrup" },
    { "id": "4622", "name": "4622 Havdrup" },
    { "id": "4623", "name": "4623 Lille Skensved" },
    { "id": "4632", "name": "4632 Bjæverskov" },
    { "id": "4640", "name": "4640 Fakse" },
    { "id": "4652", "name": "4652 Hårlev" },
    { "id": "4653", "name": "4653 Karise" },
    { "id": "4654", "name": "4654 Fakse Ladeplads" },
    { "id": "4660", "name": "4660 Store Heddinge" },
    { "id": "4671", "name": "4671 Strøby" },
    { "id": "4672", "name": "4672 Klippinge" },
    { "id": "4673", "name": "4673 Rødvig Stevns" },
    { "id": "4681", "name": "4681 Herfølge" },
    { "id": "4682", "name": "4682 Tureby" },
    { "id": "4683", "name": "4683 Rønnede" },
    { "id": "4684", "name": "4684 Holme-Olstrup" },
    { "id": "4690", "name": "4690 Haslev" },
    { "id": "4700", "name": "4700 Næstved" },
    { "id": "4720", "name": "4720 Præstø" },
    { "id": "4733", "name": "4733 Tappernøje" },
    { "id": "4735", "name": "4735 Mern" },
    { "id": "4736", "name": "4736 Karrebæksminde" },
    { "id": "4750", "name": "4750 Lundby" },
    { "id": "4760", "name": "4760 Vordingborg" },
    { "id": "4771", "name": "4771 Kalvehave" },
    { "id": "4772", "name": "4772 Langebæk" },
    { "id": "4773", "name": "4773 Stensved" },
    { "id": "4780", "name": "4780 Stege" },
    { "id": "4791", "name": "4791 Borre" },
    { "id": "4792", "name": "4792 Askeby" },
    { "id": "4793", "name": "4793 Bogø By" },
    { "id": "4800", "name": "4800 Nykøbing F" },
    { "id": "4840", "name": "4840 Nørre Alslev" },
    { "id": "4850", "name": "4850 Stubbekøbing" },
    { "id": "4862", "name": "4862 Guldborg" },
    { "id": "4863", "name": "4863 Eskilstrup" },
    { "id": "4871", "name": "4871 Horbelev" },
    { "id": "4872", "name": "4872 Idestrup" },
    { "id": "4873", "name": "4873 Væggerløse" },
    { "id": "4874", "name": "4874 Gedser" },
    { "id": "4880", "name": "4880 Nysted" },
    { "id": "4891", "name": "4891 Toreby L" },
    { "id": "4892", "name": "4892 Kettinge" },
    { "id": "4894", "name": "4894 Øster Ulslev" },
    { "id": "4895", "name": "4895 Errindlev" },
    { "id": "4900", "name": "4900 Nakskov" },
    { "id": "4912", "name": "4912 Harpelunde" },
    { "id": "4913", "name": "4913 Horslunde" },
    { "id": "4920", "name": "4920 Søllested" },
    { "id": "4930", "name": "4930 Maribo" },
    { "id": "4941", "name": "4941 Bandholm" },
    { "id": "4943", "name": "4943 Torrig L" },
    { "id": "4944", "name": "4944 Fejø" },
    { "id": "4951", "name": "4951 Nørreballe" },
    { "id": "4952", "name": "4952 Stokkemarke" },
    { "id": "4953", "name": "4953 Vesterborg" },
    { "id": "4960", "name": "4960 Holeby" },
    { "id": "4970", "name": "4970 Rødby" },
    { "id": "4983", "name": "4983 Dannemare" },
    { "id": "4990", "name": "4990 Sakskøbing" },
    { "id": "5000", "name": "5000 Odense C" },
    { "id": "5200", "name": "5200 Odense V" },
    { "id": "5210", "name": "5210 Odense NV" },
    { "id": "5220", "name": "5220 Odense SØ" },
    { "id": "5230", "name": "5230 Odense M" },
    { "id": "5240", "name": "5240 Odense NØ" },
    { "id": "5250", "name": "5250 Odense SV" },
    { "id": "5260", "name": "5260 Odense S" },
    { "id": "5270", "name": "5270 Odense N" },
    { "id": "5290", "name": "5290 Marslev" },
    { "id": "5300", "name": "5300 Kerteminde" },
    { "id": "5330", "name": "5330 Munkebo" },
    { "id": "5350", "name": "5350 Rynkeby" },
    { "id": "5370", "name": "5370 Mesinge" },
    { "id": "5380", "name": "5380 Dalby" },
    { "id": "5390", "name": "5390 Martofte" },
    { "id": "5400", "name": "5400 Bogense" },
    { "id": "5450", "name": "5450 Otterup" },
    { "id": "5462", "name": "5462 Morud" },
    { "id": "5463", "name": "5463 Harndrup" },
    { "id": "5464", "name": "5464 Brenderup" },
    { "id": "5466", "name": "5466 Asperup" },
    { "id": "5471", "name": "5471 Søndersø" },
    { "id": "5474", "name": "5474 Veflinge" },
    { "id": "5485", "name": "5485 Skamby" },
    { "id": "5491", "name": "5491 Blommenslyst" },
    { "id": "5492", "name": "5492 Vissenbjerg" },
    { "id": "5500", "name": "5500 Middelfart" },
    { "id": "5540", "name": "5540 Ullerslev" },
    { "id": "5550", "name": "5550 Langeskov" },
    { "id": "5560", "name": "5560 Aarup" },
    { "id": "5580", "name": "5580 Nørre Aaby" },
    { "id": "5591", "name": "5591 Gelsted" },
    { "id": "5592", "name": "5592 Ejby" },
    { "id": "5600", "name": "5600 Faaborg" },
    { "id": "5610", "name": "5610 Assens" },
    { "id": "5620", "name": "5620 Glamsbjerg" },
    { "id": "5631", "name": "5631 Ebberup" },
    { "id": "5642", "name": "5642 Millinge" },
    { "id": "5672", "name": "5672 Broby" },
    { "id": "5683", "name": "5683 Haarby" },
    { "id": "5690", "name": "5690 Tommerup" },
    { "id": "5700", "name": "5700 Svendborg" },
    { "id": "5750", "name": "5750 Ringe" },
    { "id": "5762", "name": "5762 Vester Skerninge" },
    { "id": "5771", "name": "5771 Stenstrup" },
    { "id": "5772", "name": "5772 Kværndrup" },
    { "id": "5792", "name": "5792 Årslev" },
    { "id": "5800", "name": "5800 Nyborg" },
    { "id": "5853", "name": "5853 Ørbæk" },
    { "id": "5854", "name": "5854 Gislev" },
    { "id": "5856", "name": "5856 Ryslinge" },
    { "id": "5863", "name": "5863 Ferritslev Fyn" },
    { "id": "5871", "name": "5871 Frørup" },
    { "id": "5874", "name": "5874 Hesselager" },
    { "id": "5881", "name": "5881 Skårup Fyn" },
    { "id": "5882", "name": "5882 Vejstrup" },
    { "id": "5883", "name": "5883 Oure" },
    { "id": "5884", "name": "5884 Gudme" },
    { "id": "5892", "name": "5892 Gudbjerg" },
    { "id": "5900", "name": "5900 Rudkøbing" },
    { "id": "5932", "name": "5932 Humble" },
    { "id": "5935", "name": "5935 Bagenkop" },
    { "id": "5953", "name": "5953 Tranekær" },
    { "id": "5960", "name": "5960 Marstal" },
    { "id": "5970", "name": "5970 Ærøskøbing" },
    { "id": "5985", "name": "5985 Søby Ærø" },
    { "id": "6000", "name": "6000 Kolding" },
    { "id": "6040", "name": "6040 Egtved" },
    { "id": "6051", "name": "6051 Almind" },
    { "id": "6052", "name": "6052 Viuf" },
    { "id": "6064", "name": "6064 Jordrup" },
    { "id": "6070", "name": "6070 Christiansfeld" },
    { "id": "6091", "name": "6091 Bjert" },
    { "id": "6092", "name": "6092 Sønder Stenderup" },
    { "id": "6093", "name": "6093 Sjølund" },
    { "id": "6094", "name": "6094 Hejls" },
    { "id": "6100", "name": "6100 Haderslev" },
    { "id": "6200", "name": "6200 Aabenraa" },
    { "id": "6230", "name": "6230 Rødekro" },
    { "id": "6240", "name": "6240 Løgumkloster" },
    { "id": "6261", "name": "6261 Bredebro" },
    { "id": "6270", "name": "6270 Tønder" },
    { "id": "6280", "name": "6280 Højer" },
    { "id": "6300", "name": "6300 Gråsten" },
    { "id": "6310", "name": "6310 Broager" },
    { "id": "6320", "name": "6320 Egernsund" },
    { "id": "6330", "name": "6330 Padborg" },
    { "id": "6340", "name": "6340 Kruså" },
    { "id": "6360", "name": "6360 Tinglev" },
    { "id": "6372", "name": "6372 Bylderup-Bov" },
    { "id": "6392", "name": "6392 Bolderslev" },
    { "id": "6400", "name": "6400 Sønderborg" },
    { "id": "6430", "name": "6430 Nordborg" },
    { "id": "6440", "name": "6440 Augustenborg" },
    { "id": "6470", "name": "6470 Sydals" },
    { "id": "6500", "name": "6500 Vojens" },
    { "id": "6510", "name": "6510 Gram" },
    { "id": "6520", "name": "6520 Toftlund" },
    { "id": "6535", "name": "6535 Branderup J" },
    { "id": "6541", "name": "6541 Bevtoft" },
    { "id": "6560", "name": "6560 Sommersted" },
    { "id": "6580", "name": "6580 Vamdrup" },
    { "id": "6600", "name": "6600 Vejen" },
    { "id": "6621", "name": "6621 Gesten" },
    { "id": "6622", "name": "6622 Bække" },
    { "id": "6623", "name": "6623 Vorbasse" },
    { "id": "6630", "name": "6630 Rødding" },
    { "id": "6640", "name": "6640 Lunderskov" },
    { "id": "6650", "name": "6650 Brørup" },
    { "id": "6660", "name": "6660 Lintrup" },
    { "id": "6670", "name": "6670 Holsted" },
    { "id": "6682", "name": "6682 Hovborg" },
    { "id": "6683", "name": "6683 Føvling" },
    { "id": "6690", "name": "6690 Gørding" },
    { "id": "6700", "name": "6700 Esbjerg" },
    { "id": "6701", "name": "6701 Esbjerg" },
    { "id": "6705", "name": "6705 Esbjerg Ø" },
    { "id": "6710", "name": "6710 Esbjerg V" },
    { "id": "6715", "name": "6715 Esbjerg N" },
    { "id": "6720", "name": "6720 Fanø" },
    { "id": "6731", "name": "6731 Tjæreborg" },
    { "id": "6740", "name": "6740 Bramming" },
    { "id": "6752", "name": "6752 Glejbjerg" },
    { "id": "6760", "name": "6760 Ribe" },
    { "id": "6771", "name": "6771 Gredstedbro" },
    { "id": "6780", "name": "6780 Skærbæk" },
    { "id": "6792", "name": "6792 Rømø" },
    { "id": "6800", "name": "6800 Varde" },
    { "id": "6818", "name": "6818 Årre" },
    { "id": "6823", "name": "6823 Ansager" },
    { "id": "6830", "name": "6830 Nørre Nebel" },
    { "id": "6840", "name": "6840 Oksbøl" },
    { "id": "6851", "name": "6851 Janderup" },
    { "id": "6852", "name": "6852 Billum" },
    { "id": "6853", "name": "6853 Vejers Strand" },
    { "id": "6854", "name": "6854 Henne" },
    { "id": "6855", "name": "6855 Outrup" },
    { "id": "6857", "name": "6857 Blåvand" },
    { "id": "6862", "name": "6862 Tistrup" },
    { "id": "6870", "name": "6870 Ølgod" },
    { "id": "6880", "name": "6880 Tarm" },
    { "id": "6893", "name": "6893 Hemmet" },
    { "id": "6900", "name": "6900 Skjern" },
    { "id": "6920", "name": "6920 Videbæk" },
    { "id": "6933", "name": "6933 Kibæk" },
    { "id": "6940", "name": "6940 Lem St" },
    { "id": "6950", "name": "6950 Ringkøbing" },
    { "id": "6960", "name": "6960 Hvide Sande" },
    { "id": "6971", "name": "6971 Spjald" },
    { "id": "6973", "name": "6973 Ørnhøj" },
    { "id": "6980", "name": "6980 Tim" },
    { "id": "6990", "name": "6990 Ulfborg" },
    { "id": "7000", "name": "7000 Fredericia" },
    { "id": "7080", "name": "7080 Børkop" },
    { "id": "7100", "name": "7100 Vejle" },
    { "id": "7120", "name": "7120 Vejle Øst" },
    { "id": "7130", "name": "7130 Juelsminde" },
    { "id": "7140", "name": "7140 Stouby" },
    { "id": "7150", "name": "7150 Barrit" },
    { "id": "7160", "name": "7160 Tørring" },
    { "id": "7171", "name": "7171 Uldum" },
    { "id": "7173", "name": "7173 Vonge" },
    { "id": "7182", "name": "7182 Bredsten" },
    { "id": "7183", "name": "7183 Randbøl" },
    { "id": "7184", "name": "7184 Vandel" },
    { "id": "7190", "name": "7190 Billund" },
    { "id": "7200", "name": "7200 Grindsted" },
    { "id": "7250", "name": "7250 Hejnsvig" },
    { "id": "7260", "name": "7260 Sønder Omme" },
    { "id": "7270", "name": "7270 Stakroge" },
    { "id": "7280", "name": "7280 Sønder Felding" },
    { "id": "7300", "name": "7300 Jelling" },
    { "id": "7321", "name": "7321 Gadbjerg" },
    { "id": "7323", "name": "7323 Give" },
    { "id": "7330", "name": "7330 Brande" },
    { "id": "7361", "name": "7361 Ejstrupholm" },
    { "id": "7362", "name": "7362 Hampen" },
    { "id": "7400", "name": "7400 Herning" },
    { "id": "7430", "name": "7430 Ikast" },
    { "id": "7441", "name": "7441 Bording" },
    { "id": "7442", "name": "7442 Engesvang" },
    { "id": "7451", "name": "7451 Sunds" },
    { "id": "7470", "name": "7470 Karup J" },
    { "id": "7480", "name": "7480 Vildbjerg" },
    { "id": "7490", "name": "7490 Aulum" },
    { "id": "7500", "name": "7500 Holstebro" },
    { "id": "7540", "name": "7540 Haderup" },
    { "id": "7550", "name": "7550 Sørvad" },
    { "id": "7560", "name": "7560 Hjerm" },
    { "id": "7570", "name": "7570 Vemb" },
    { "id": "7600", "name": "7600 Struer" },
    { "id": "7620", "name": "7620 Lemvig" },
    { "id": "7650", "name": "7650 Bøvlingbjerg" },
    { "id": "7660", "name": "7660 Bækmarksbro" },
    { "id": "7673", "name": "7673 Harboøre" },
    { "id": "7680", "name": "7680 Thyborøn" },
    { "id": "7700", "name": "7700 Thisted" },
    { "id": "7730", "name": "7730 Hanstholm" },
    { "id": "7741", "name": "7741 Frøstrup" },
    { "id": "7742", "name": "7742 Vesløs" },
    { "id": "7752", "name": "7752 Snedsted" },
    { "id": "7755", "name": "7755 Bedsted Thy" },
    { "id": "7760", "name": "7760 Hurup Thy" },
    { "id": "7770", "name": "7770 Vestervig" },
    { "id": "7790", "name": "7790 Thyholm" },
    { "id": "7800", "name": "7800 Skive" },
    { "id": "7830", "name": "7830 Vinderup" },
    { "id": "7840", "name": "7840 Højslev" },
    { "id": "7850", "name": "7850 Stoholm, Jylland" },
    { "id": "7860", "name": "7860 Spøttrup" },
    { "id": "7870", "name": "7870 Roslev" },
    { "id": "7884", "name": "7884 Fur" },
    { "id": "7900", "name": "7900 Nykøbing M" },
    { "id": "7950", "name": "7950 Erslev" },
    { "id": "7960", "name": "7960 Karby" },
    { "id": "7970", "name": "7970 Redsted M" },
    { "id": "7980", "name": "7980 Vils" },
    { "id": "7990", "name": "7990 Øster Assels" },
    { "id": "8000", "name": "8000 Århus C" },
    { "id": "8200", "name": "8200 Århus N" },
    { "id": "8210", "name": "8210 Århus V" },
    { "id": "8220", "name": "8220 Brabrand" },
    { "id": "8230", "name": "8230 Åbyhøj" },
    { "id": "8240", "name": "8240 Risskov" },
    { "id": "8250", "name": "8250 Egå" },
    { "id": "8260", "name": "8260 Viby J" },
    { "id": "8270", "name": "8270 Højbjerg" },
    { "id": "8300", "name": "8300 Odder" },
    { "id": "8305", "name": "8305 Samsø" },
    { "id": "8310", "name": "8310 Tranbjerg J" },
    { "id": "8320", "name": "8320 Mårslet" },
    { "id": "8330", "name": "8330 Beder" },
    { "id": "8340", "name": "8340 Malling" },
    { "id": "8350", "name": "8350 Hundslund" },
    { "id": "8355", "name": "8355 Solbjerg" },
    { "id": "8361", "name": "8361 Hasselager" },
    { "id": "8362", "name": "8362 Hørning" },
    { "id": "8370", "name": "8370 Hadsten" },
    { "id": "8380", "name": "8380 Trige" },
    { "id": "8381", "name": "8381 Tilst" },
    { "id": "8382", "name": "8382 Hinnerup" },
    { "id": "8400", "name": "8400 Ebeltoft" },
    { "id": "8410", "name": "8410 Rønde" },
    { "id": "8420", "name": "8420 Knebel" },
    { "id": "8444", "name": "8444 Balle" },
    { "id": "8450", "name": "8450 Hammel" },
    { "id": "8462", "name": "8462 Harlev J" },
    { "id": "8464", "name": "8464 Galten" },
    { "id": "8471", "name": "8471 Sabro" },
    { "id": "8472", "name": "8472 Sporup" },
    { "id": "8500", "name": "8500 Grenaa" },
    { "id": "8520", "name": "8520 Lystrup" },
    { "id": "8530", "name": "8530 Hjortshøj" },
    { "id": "8541", "name": "8541 Skødstrup" },
    { "id": "8543", "name": "8543 Hornslet" },
    { "id": "8544", "name": "8544 Mørke" },
    { "id": "8550", "name": "8550 Ryomgård" },
    { "id": "8560", "name": "8560 Kolind" },
    { "id": "8570", "name": "8570 Trustrup" },
    { "id": "8581", "name": "8581 Nimtofte" },
    { "id": "8585", "name": "8585 Glesborg" },
    { "id": "8586", "name": "8586 Ørum Djurs" },
    { "id": "8592", "name": "8592 Anholt" },
    { "id": "8600", "name": "8600 Silkeborg" },
    { "id": "8620", "name": "8620 Kjellerup" },
    { "id": "8632", "name": "8632 Lemming" },
    { "id": "8641", "name": "8641 Sorring" },
    { "id": "8643", "name": "8643 Ans by" },
    { "id": "8653", "name": "8653 Them" },
    { "id": "8654", "name": "8654 Bryrup" },
    { "id": "8660", "name": "8660 Skanderborg" },
    { "id": "8670", "name": "8670 Låsby" },
    { "id": "8680", "name": "8680 Ry" },
    { "id": "8700", "name": "8700 Horsens" },
    { "id": "8721", "name": "8721 Daugård" },
    { "id": "8722", "name": "8722 Hedensted" },
    { "id": "8723", "name": "8723 Løsning" },
    { "id": "8732", "name": "8732 Hovedgård" },
    { "id": "8740", "name": "8740 Brædstrup" },
    { "id": "8751", "name": "8751 Gedved" },
    { "id": "8752", "name": "8752 Østbirk" },
    { "id": "8762", "name": "8762 Flemming" },
    { "id": "8763", "name": "8763 Rask Mølle" },
    { "id": "8765", "name": "8765 Klovborg" },
    { "id": "8766", "name": "8766 Nørre Snede" },
    { "id": "8781", "name": "8781 Stenderup" },
    { "id": "8783", "name": "8783 Hornsyld" },
    { "id": "8800", "name": "8800 Viborg" },
    { "id": "8830", "name": "8830 Tjele" },
    { "id": "8831", "name": "8831 Løgstrup" },
    { "id": "8832", "name": "8832 Skals" },
    { "id": "8840", "name": "8840 Rødkærsbro" },
    { "id": "8850", "name": "8850 Bjerringbro" },
    { "id": "8860", "name": "8860 Ulstrup" },
    { "id": "8870", "name": "8870 Langå" },
    { "id": "8881", "name": "8881 Thorsø" },
    { "id": "8882", "name": "8882 Fårvang" },
    { "id": "8883", "name": "8883 Gjern" },
    { "id": "8900", "name": "8900 Randers" },
    { "id": "8950", "name": "8950 Ørsted" },
    { "id": "8961", "name": "8961 Allingåbro" },
    { "id": "8963", "name": "8963 Auning" },
    { "id": "8970", "name": "8970 Havndal" },
    { "id": "8981", "name": "8981 Spenstrup" },
    { "id": "8983", "name": "8983 Gjerlev J" },
    { "id": "8990", "name": "8990 Fårup" },
    { "id": "9000", "name": "9000 Aalborg" },
    { "id": "9200", "name": "9200 Aalborg SV" },
    { "id": "9210", "name": "9210 Aalborg SØ" },
    { "id": "9220", "name": "9220 Aalborg Øst" },
    { "id": "9230", "name": "9230 Svenstrup J" },
    { "id": "9240", "name": "9240 Nibe" },
    { "id": "9260", "name": "9260 Gistrup" },
    { "id": "9270", "name": "9270 Klarup" },
    { "id": "9280", "name": "9280 Storvorde" },
    { "id": "9293", "name": "9293 Kongerslev" },
    { "id": "9300", "name": "9300 Sæby" },
    { "id": "9310", "name": "9310 Vodskov" },
    { "id": "9320", "name": "9320 Hjallerup" },
    { "id": "9330", "name": "9330 Dronninglund" },
    { "id": "9340", "name": "9340 Asaa" },
    { "id": "9352", "name": "9352 Dybvad" },
    { "id": "9362", "name": "9362 Gandrup" },
    { "id": "9370", "name": "9370 Hals" },
    { "id": "9380", "name": "9380 Vestbjerg" },
    { "id": "9381", "name": "9381 Sulsted" },
    { "id": "9382", "name": "9382 Tylstrup" },
    { "id": "9400", "name": "9400 Nørresundby" },
    { "id": "9430", "name": "9430 Vadum" },
    { "id": "9440", "name": "9440 Aabybro" },
    { "id": "9460", "name": "9460 Brovst" },
    { "id": "9480", "name": "9480 Løkken" },
    { "id": "9490", "name": "9490 Pandrup" },
    { "id": "9492", "name": "9492 Blokhus" },
    { "id": "9493", "name": "9493 Saltum" },
    { "id": "9500", "name": "9500 Hobro" },
    { "id": "9510", "name": "9510 Arden" },
    { "id": "9520", "name": "9520 Skørping" },
    { "id": "9530", "name": "9530 Støvring" },
    { "id": "9541", "name": "9541 Suldrup" },
    { "id": "9550", "name": "9550 Mariager" },
    { "id": "9560", "name": "9560 Hadsund" },
    { "id": "9574", "name": "9574 Bælum" },
    { "id": "9575", "name": "9575 Terndrup" },
    { "id": "9600", "name": "9600 Aars" },
    { "id": "9610", "name": "9610 Nørager" },
    { "id": "9620", "name": "9620 Aalestrup" },
    { "id": "9631", "name": "9631 Gedsted" },
    { "id": "9632", "name": "9632 Møldrup" },
    { "id": "9640", "name": "9640 Farsø" },
    { "id": "9670", "name": "9670 Løgstør" },
    { "id": "9681", "name": "9681 Ranum" },
    { "id": "9690", "name": "9690 Fjerritslev" },
    { "id": "9700", "name": "9700 Brønderslev" },
    { "id": "9740", "name": "9740 Jerslev J" },
    { "id": "9750", "name": "9750 Øster Vrå" },
    { "id": "9760", "name": "9760 Vrå" },
    { "id": "9800", "name": "9800 Hjørring" },
    { "id": "9830", "name": "9830 Tårs" },
    { "id": "9850", "name": "9850 Hirtshals" },
    { "id": "9870", "name": "9870 Sindal" },
    { "id": "9881", "name": "9881 Bindslev" },
    { "id": "9900", "name": "9900 Frederikshavn" },
    { "id": "9940", "name": "9940 Læsø" },
    { "id": "9970", "name": "9970 Strandby" },
    { "id": "9981", "name": "9981 Jerup" },
    { "id": "9982", "name": "9982 Ålbæk" },
    { "id": "9990", "name": "9990 Skagen" }
]

const getZipDisplayName = (zip: string) => {
    const formattedZip = zip.split(" ").join('').toLocaleLowerCase()
    const initialResult = zipMap.find((i) => i.id === formattedZip)
    if (initialResult) return initialResult.name
    else return formattedZip + " - Unkown"
}


const methods = { getZipDisplayName, zipMap }

export default methods