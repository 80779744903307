

export const SmallGap = 12

export const LargeGap = 32

export const MarketingSiteUrl = "https://venando.dk/"

const isLocal = !!process.env.REACT_APP_API?.includes("local")

export const AppUrl = isLocal ? "http://localhost:5001/" : "https://www.app.venando.dk/"

export const formMaxWidth = 355

export const dynamicFormWidth = window.innerWidth > formMaxWidth ? formMaxWidth : window.innerWidth-62

export const PrimaryColor = '#2693c7'

export const defaultAgencyIcon = "https://venando-prod-attachments-bucket.s3.eu-west-1.amazonaws.com/8d1457e8-ca18-4c27-ae18-a5e7048059ba.png"

export const VenandoLogo = "https://uploads-ssl.webflow.com/64f8671b332495b34b825240/64f879b2332495b34b9a63ba_venando_logo-p-500.png"

export const isMobile = window.innerWidth < 768

export function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}