import * as React from 'react'
import hooks from '../utils/hooks'
import { useNavigate } from 'react-router-dom'
import { Stack, StackItem } from '@fluentui/react'
import { LoginBody } from '../components/wrappers'
import { SmallGap } from '../utils/globals'
import { ErrorContext } from '../components/wrappers';
import { InputWithLabel, validateEmail } from '../components/Fields'
import { ButtonWithLoading } from '../components/Button'

const SignupUserPage: React.FC = () => {
    let navigate = useNavigate()
    const { showErrorMessage } = React.useContext(ErrorContext)

    const [email, setEmail] = React.useState<string>()
    const [phone, setPhone] = React.useState<string>()

    const handleSubmissionEvent = async () => {
        if (!email) return
        const result = await hooks.startLogin({ email, agentSignIn: true, phone })
        if (result) {
            navigate('/confirmlogin/' + result.loginChallengeId)
        } else {
            showErrorMessage("Der skete en fejl")
        }
    }

    React.useEffect(() => {
        (async () => {
            const result = await hooks.testLogin()
            if (result) navigate('/')
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const invalid = !validateEmail(email)

    return (
        <LoginBody>
            <Stack tokens={{ childrenGap: SmallGap }}>
                <Stack>
                    <StackItem><h1>Velkommen til Venando</h1></StackItem>
                    <StackItem><h3>Opret din profil</h3></StackItem>
                    <StackItem><p>Når dit kontor er godkendt af Venando, så kan du invitere resten af dit team til systemet.</p></StackItem>
                </Stack>

                <InputWithLabel setValue={setEmail} value={email} label='Din email' type='email'  />
                <InputWithLabel setValue={setPhone} value={phone} label='Dit telefonnummer' type='tel'  />

                <StackItem> <ButtonWithLoading text="Næste" onClick={handleSubmissionEvent} disabled={invalid || !email} /></StackItem>

            </Stack>
        </LoginBody >
    )
}

export default SignupUserPage