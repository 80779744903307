import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import hooks from '../utils/hooks'
import { Dropdown, ISelectableOption, Stack } from '@fluentui/react'
import { AppBody } from '../components/wrappers'
import { Agency, Agent } from '@venando/common'
import { LargeGap, PrimaryColor, SmallGap, defaultAgencyIcon, isMobile } from '../utils/globals'
import { FileInput, FileTypes, InputWithLabel, InputWrapper, MultilineInputWithLabel } from '../components/Fields'
import { ButtonWithLoading } from '../components/Button'


const IconList = [
    { name: "EDC", id: "https://venando-prod-attachments-bucket.s3.eu-west-1.amazonaws.com/c4d24ede-fc33-4736-8c04-12b676a4c1fc.png" },
    { name: "Nybolig", id: "https://venando-prod-attachments-bucket.s3.eu-west-1.amazonaws.com/cbeee92f-dca2-426e-a3aa-e19a7e7c6829.png" },
    { name: "Home", id: "https://venando-prod-attachments-bucket.s3.eu-west-1.amazonaws.com/237f8f78-7335-4482-9812-adefdf3cee77.png" },
    { name: "Danbolig", id: "https://venando-prod-attachments-bucket.s3.eu-west-1.amazonaws.com/a6d8d80a-965e-4f9b-954e-403f578f5270.png" },
    { name: "LokalBolig", id: "https://venando-prod-attachments-bucket.s3.eu-west-1.amazonaws.com/6a2227ac-77f3-4dc0-b58c-9630f05a2e96.png" },
    { name: "RealMæglerne", id: "https://venando-prod-attachments-bucket.s3.eu-west-1.amazonaws.com/44836707-3c81-44f8-98c6-726359dddf2c.png" },
    { name: "Estate", id: "https://venando-prod-attachments-bucket.s3.eu-west-1.amazonaws.com/303dd830-3a19-4f21-833c-9e20c7972c31.png" },
]

const SelectIconInput: React.FC<{ setIcon(icon?: string): void, icon?: string }> = ({ setIcon, icon }) => {

    const onRenderLabel = (props?: ISelectableOption<{ key: string, text: string }>): JSX.Element => {
        if (!props) return <></>
        return (
            <Stack horizontal verticalAlign="center">
                <img width={20} height={20} src={props.key.toString()} alt='' />
                <span style={{ marginLeft: 6 }}>{props.text}</span>
            </Stack>
        );
    };
    return (
        <Dropdown
            selectedKey={icon}
            onChange={(_, option) => setIcon(option?.key.toString())}
            placeholder="Vælg et ikon"
            label="Forudvalgte ikoner"
            onRenderOption={(item) => onRenderLabel(item)}
            options={IconList.map((i) => ({ key: i.id, text: i.name }))}
        />
    )
}

const EditCompanyProfilePage: React.FC = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = React.useState(true)
    const [agency, setAgency] = React.useState<Agency>()
    const [agent, setAgent] = React.useState<Agent>()
    const [name, setName] = React.useState<string>()
    const [description, setDescription] = React.useState<string | undefined>()
    const [icon, setIcon] = React.useState<string>()

    React.useEffect(() => {
        (async () => {
            setLoading(true)
            try {
                const result = await hooks.getAgentAndAgency()
                if (result && result.agency) {
                    const status = hooks.checkAgencyStatus(result.agency, navigate)
                    
                    setAgency(result.agency)
                    setAgent(result.agent)
                    setName(result.agency.companyProfileName)
                    setDescription(result.agency.companyProfileDescription)
                    setIcon(result.agency.companyProfileIcon)
                    if(status) setLoading(false)
                }
            } catch (error) {
                navigate('/error')
            }
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleDescriptionChange = (updatedDescription?: string) => {
        if (updatedDescription && updatedDescription.length < 360) setDescription(updatedDescription)
        else setDescription(updatedDescription?.slice(0, 360))
    }

    const handleUpdate = async () => {
        await hooks.updateAgency({
            companyProfileName: name || '',
            companyProfileIcon: icon || defaultAgencyIcon,
            companyProfileDescription: description || ''
        })
    }

    return (
        <AppBody loading={loading}>
            <Stack tokens={{ childrenGap: LargeGap }}>
                <Stack.Item><h2>Rediger din forretningsprofil</h2></Stack.Item>
                <Stack horizontal={!isMobile} tokens={{ childrenGap: LargeGap }}>
                    <Stack style={{ minWidth: 250 }}>
                        <InputWithLabel label='Forretningens navn' setValue={setName} value={name} loading={loading || !agent} />
                        <MultilineInputWithLabel label={'Beskriv din profil (' + (description?.length || 0) + '/360)'} setValue={handleDescriptionChange} value={description} loading={loading || !agent} />
                        <InputWrapper label='Vælg mægler ikon' loading={loading}>
                            <Stack tokens={{ childrenGap: SmallGap }}>
                                <SelectIconInput setIcon={setIcon} icon={icon} />
                                <span style={{ textAlign: 'center' }}>Eller upload dit eget ikon (1:1)</span>
                                <FileInput setter={setIcon} loading={loading} value={icon} allowedFileTypes={FileTypes.imageFileTypes} />
                            </Stack>
                        </InputWrapper>
                    </Stack>
                    <Stack.Item>
                        <div style={{ width: 250, borderWidth: 1, borderStyle: 'solid', borderColor: "#ebebef", padding: LargeGap }}>
                            <Stack tokens={{ childrenGap: SmallGap }}>
                                <img alt='Ikon' src={icon || defaultAgencyIcon} style={{ width: 50, height: 50, borderRadius: 200, backgroundColor: icon ? 'transparent' : PrimaryColor }} />
                                <Stack horizontal><div style={{ backgroundColor: '#f3f3fc', color: PrimaryColor, padding: "6px 12px" }}>{agency?.companyProfileName}</div></Stack>
                                <p style={{ color: "#84889a", fontSize: 12 }}>{description}</p>
                            </Stack>
                        </div>
                    </Stack.Item>
                </Stack>
                <Stack horizontal>
                    <ButtonWithLoading text='Gem ændringer' onClick={handleUpdate} />
                </Stack>
            </Stack>
        </AppBody>
    )
}

export default EditCompanyProfilePage