import * as React from 'react';
import {
    SelectionMode, ShimmeredDetailsList, DefaultButton,
    Panel, PanelType, Persona, PersonaSize, Pivot, PivotItem, Stack
} from '@fluentui/react';
import { BuyerLead, SearchSetting, User } from '@venando/common';
import zipcodeLib from '../utils/zipcode-lib';
import { translatePropertyTypes, AreaWishes, SpecialWishes, PropertyTypes } from '../utils/property-types';
import { DateStringToNiceDate, NiceNumberWithCurrency } from '../utils/formatters';
import { LargeGap, PrimaryColor, SmallGap } from '../utils/globals';
import Tags from "./Tags"
import softValuesTypes from '../utils/soft-values-types';
import { useSearchParams } from 'react-router-dom';
import _ from "lodash"
import { AreaHeader, BudgetHeader, NameHeader, PropertyTypeHeader, RoomsHeader, SortHeader, SquareMetersHeader } from './SearchSettingComponent';

const getCriteriaTitle = (criteria: BuyerLead) => {
    return zipcodeLib.getZipDisplayName(criteria.areaId) + " - " + translatePropertyTypes(criteria.propertyType)
}

const DataTable: React.FC<{ data: { label: React.ReactNode, value: React.ReactNode }[] }> = ({ data }) => {

    return (
        <table style={{ width: '100%' }}>
            <tbody>
                {data.map(({ label, value }, key) => (
                    <tr key={key} style={{ height: 30, backgroundColor: key % 2 === 0 ? "#dfdfdf" : "#eee" }}>
                        <td style={{ padding: 4 }}>{label}</td>
                        <td style={{ textAlign: 'right', padding: 4 }}>{value}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}


const Spacer: React.FC = () => <div style={{ width: 120, height: 4, backgroundColor: PrimaryColor, marginTop: LargeGap }}></div>


const CustomerPanelView: React.FC<{ user: User, validAreas: string[], displayName: string }> = ({ user, validAreas, displayName }) => {
    const [open, setOpen] = React.useState(false)
    const [selectedIndex, setSelectedIndex] = React.useState(0)

    let [searchParams] = useSearchParams()
    const queryParameters = new URLSearchParams(searchParams);
    const preselectedUserId = queryParameters.get('userId')
    const ValidCriterias = user.criterias.filter(({ areaId }) => validAreas.includes(areaId))

    React.useEffect(() => {
        if (preselectedUserId && preselectedUserId === user.userId) setOpen(true)
        //eslint-disable-next-line
    }, [preselectedUserId])

    const enterView = () => {
        setOpen(true)
    }

    const exitView = () => {
        setOpen(false)
    }

    const existingPropertyName = softValuesTypes.PropertySituationStatus.find((i) => i.id === user?.existingPropertyType)

    const RenderPivotContent = () => ValidCriterias.map((item) => (
        <Stack>
            <DataTable data={[
                { label: "Ønsket ejendomstype", value: translatePropertyTypes(item.propertyType) },
                { label: "Budget", value: item.maxPrice ? NiceNumberWithCurrency(item.maxPrice) : "Ikke udfyldt" },
                { label: "Størrelse", value: item.squareMeters ? item.squareMeters + " m2" : "Ikke udfyldt" },
                { label: "Antal værelser - inklusiv stue (minimum)", value: item.rooms ? item.rooms + " værelser" : "Ikke udfyldt" },
                { label: "Antal etager", value: item.floors ? item.floors + " etage(r)" : "Ikke udfyldt" },
                { label: "Antal badværelser", value: item.bathrooms ? item.bathrooms + " badværelse(r)" : "Ikke udfyldt" },
            ]} />
            <Spacer />
            <h3>Ønsker til ejendommen</h3>
            <Tags.TagList selected={item.propertyRequests || []} options={SpecialWishes} />
            {(item.propertyRequests === undefined || item.propertyRequests.length === 0) && <span>Ingen ønsker til ejendommen</span>}
            <Spacer />
            <h3>Ønsker til området</h3>
            <Tags.TagList selected={item.areaRequests || []} options={AreaWishes} />
            {(item.areaRequests === undefined || item.areaRequests.length === 0) && <span>Ingen ønsker til området</span>}
        </Stack>
    ))

    const RenderAbout = () => (
        <Stack tokens={{ childrenGap: SmallGap }}>
            <h3>Husstanden</h3>
            <span>Nuværende bolig type: <b>{existingPropertyName ? existingPropertyName.name : "Ikke udfyldt"}</b></span>
            <DataTable data={[
                { label: "Antal børn", value: (user.userSoftValues?.householdKids && user.userSoftValues.householdKids + " børn") || "Ikke udfyldt" },
                { label: "Antal voksne", value: user.userSoftValues?.householdAdults && user.userSoftValues?.householdAdults > 0 ? user.userSoftValues.householdAdults + " voksne" : "Ikke udfyldt" },
            ]} />
            <Spacer />
            <p>Når {user.name} er fysisk aktiv</p>
            <Tags.TagList selected={user.userSoftValues?.sportsActivities || []} options={softValuesTypes.sportsOptions} />
            <Spacer />
            <p>Interesser i hjemme hos {user.name}</p>
            <Tags.TagList selected={user.userSoftValues?.interest || []} options={softValuesTypes.interestOptions} />

            <Spacer />
            <p>Når {user.name} er ude med dine nærmeste</p>
            <Tags.TagList selected={user.userSoftValues?.culture || []} options={softValuesTypes.cultureOptions} />

            <Spacer />
            <p>Hvordan kommer {user.name} på arbejde?</p>
            <Tags.TagList selected={user.userSoftValues?.commute || []} options={softValuesTypes.commuteOptions} />

            <Spacer />
            <p>Er der en bedste ven som skal flytte med {user.name}?</p>
            <Tags.TagList selected={user.userSoftValues?.pet || []} options={softValuesTypes.petOptions} />

            <Spacer />
            <p>{user.name} fremtidsdrøm for den næste bolig?</p>
            <Tags.TagList selected={user.userSoftValues?.family || []} options={softValuesTypes.familyOptions} />
        </Stack>
    )

    const renderItem = (key: number) => {
        console.log({ key })
        const options = [...RenderPivotContent(), RenderAbout()]
        return options[key]
    }


    return (
        <>
            <DefaultButton text="Se mere" onClick={enterView} />
            <Panel isLightDismiss
                isOpen={open}
                onDismiss={exitView}
                type={PanelType.medium}
                closeButtonAriaLabel="Close"
            >
                <Stack>
                    <Persona text={user.name} size={PersonaSize.size72} secondaryText={user.email} tertiaryText={user.phone} />
                    <Stack horizontal style={{ overflow: 'scroll' }}>
                        <Pivot defaultSelectedKey={displayName} style={{ marginTop: LargeGap }} onLinkClick={(item) => setSelectedIndex(Number(item?.props.itemKey))}>
                            {ValidCriterias.map((item, key) => (
                                <PivotItem key={key} headerText={getCriteriaTitle(item)} itemKey={key.toString()} style={{ paddingTop: SmallGap }}></PivotItem>
                            ))}
                            <PivotItem headerText={"Om " + user.name} itemKey={(ValidCriterias.length).toString()} style={{ paddingTop: SmallGap }}></PivotItem>
                        </Pivot>
                    </Stack>
                    {renderItem(selectedIndex)}

                </Stack>
            </Panel>
        </>
    )
}

const CustomerList: React.FC<{ loading: boolean, customers: User[], validAreas: string[], searchSetting: SearchSetting, setSearchSetting(_: SearchSetting): void }> = ({ loading, customers, validAreas, searchSetting, setSearchSetting }) => {
    const [localCustomers, setCustomers] = React.useState<User[]>(customers)

    React.useEffect(() => {
        setCustomers([])
        let local = customers
        if (searchSetting.name) local = local.filter((c) => c.name.toLocaleLowerCase().includes(searchSetting.name?.toLocaleLowerCase() || ''))
        if (searchSetting.areaId) local = local.filter((c) => c.criterias.find((cc) => cc.areaId === searchSetting.areaId))
        if (searchSetting.maxPrice && searchSetting.maxPrice > 0) local = local.filter((c) => c.criterias.find((cc) => cc.maxPrice && searchSetting.maxPrice && (cc.maxPrice >= searchSetting.maxPrice)))
        if (searchSetting.minPrice && searchSetting.minPrice > 0) local = local.filter((c) => c.criterias.find((cc) => cc.maxPrice && searchSetting.minPrice && (cc.maxPrice <= searchSetting.minPrice)))
        if (searchSetting.propertyType) local = local.filter((c) => c.criterias.find((cc) => cc.propertyType === searchSetting.propertyType))
        if (searchSetting.minSquareMeters && searchSetting.minSquareMeters > 0) local = local.filter((c) => c.criterias.find((cc) => cc.squareMeters && searchSetting.minSquareMeters && (cc.squareMeters >= searchSetting.minSquareMeters)))
        if (searchSetting.maxSquareMeters && searchSetting.maxSquareMeters > 0) local = local.filter((c) => c.criterias.find((cc) => cc.squareMeters && searchSetting.maxSquareMeters && (cc.squareMeters <= searchSetting.maxSquareMeters)))
        if (searchSetting.rooms && searchSetting.rooms.length > 0) local = local.filter((c) => c.criterias.find((cc) => cc.rooms && searchSetting.rooms?.includes(cc.rooms)))


        const getCriteriaPrice = (criterias: BuyerLead[]) => criterias.reduce((acc, i) => i.maxPrice && i.maxPrice > acc ? i.maxPrice : acc, 0)
        if (searchSetting.sorting === 'created-date') local = local.sort((a, b) => (new Date(b.createdAt)).getTime() - (new Date(a.createdAt)).getTime())
        if (searchSetting.sorting === 'price-asc') local = local.sort((a, b) => getCriteriaPrice(b.criterias) - getCriteriaPrice(a.criterias))
        if (searchSetting.sorting === 'price-desc') local = local.sort((a, b) => getCriteriaPrice(a.criterias) - getCriteriaPrice(b.criterias))


        setCustomers([...local])
    }, [customers, searchSetting])

    return (
        <Stack>
            <Stack horizontalAlign='end'>
                <SortHeader searchSetting={searchSetting} setSearchSetting={setSearchSetting} />
            </Stack>
            <ShimmeredDetailsList
                items={localCustomers.map((item, key) => {
                    const displayName = item.criterias.map(({ areaId }) => areaId).join(', ')
                    const areaIdList = item.criterias.map(({ areaId }) => areaId)
                    const maxPrice = item.criterias.reduce((acc, i) => i.maxPrice && i.maxPrice > acc ? i.maxPrice : acc, 0)
                    const squareMeters = _.uniq(item.criterias.map((i) => i.squareMeters).filter((i) => i && i > 0)).map((i) => i + " m2").join(', ') || "Ikke udfyldt"
                    const rooms = _.uniq(item.criterias.reduce((acc: number[], i) => {
                        if (i.rooms && i.rooms > 0) return [...acc, i.rooms]
                        else return acc
                    }, [])).map((i) => i === 4 ? "+4 værelser" : (i + " værelse(r)"))


                    const areaIds = (
                        <Stack horizontal wrap tokens={{ childrenGap: SmallGap }}>
                            <Tags.TagList selected={validAreas.filter((i) => areaIdList.includes(i))} options={areaIdList.map((item) => ({ id: item, name: zipcodeLib.getZipDisplayName(item) }))} />
                        </Stack>)

                    const propertyTypesList: string[] = []
                    item.criterias.map((i) => !!i.propertyType && propertyTypesList.push(PropertyTypes.find(({ id }) => id === i.propertyType)?.name || i.propertyType))
                    const created = DateStringToNiceDate(item.createdAt)
                    return {
                        ...item, squareMeters, propertyTypesList: _.uniq(propertyTypesList).join(', '), maxPrice: maxPrice > 0 ? NiceNumberWithCurrency(maxPrice) : "Ikke oplyst",
                        rooms,
                        areaIds, created, details: <CustomerPanelView user={item} key={key} validAreas={validAreas} displayName={displayName} />
                    }
                })}
                columns={[
                    { key: 'name', name: 'Navn', fieldName: 'name', minWidth: 180, maxWidth: 200, isResizable: true, onRenderHeader: () => <NameHeader searchSetting={searchSetting} setSearchSetting={setSearchSetting} /> },
                    { key: 'displayName', name: 'Område(r)', fieldName: 'areaIds', minWidth: 200, isResizable: true, onRenderHeader: () => <AreaHeader searchSetting={searchSetting} setSearchSetting={setSearchSetting} areas={validAreas} /> },
                    { key: 'propertyTypesList', name: 'Type', fieldName: 'propertyTypesList', minWidth: 150, isResizable: true, onRenderHeader: () => <PropertyTypeHeader searchSetting={searchSetting} setSearchSetting={setSearchSetting} /> },
                    { key: 'maxPrice', name: 'Budget', fieldName: 'maxPrice', minWidth: 150, isResizable: true, onRenderHeader: () => <BudgetHeader searchSetting={searchSetting} setSearchSetting={setSearchSetting} /> },
                    { key: 'squareMeters', name: 'M2', fieldName: 'squareMeters', minWidth: 150, isResizable: true, onRenderHeader: () => <SquareMetersHeader searchSetting={searchSetting} setSearchSetting={setSearchSetting} /> },
                    { key: 'rooms', name: 'Værelser', fieldName: 'rooms', minWidth: 150, isResizable: true, onRenderHeader: () => <RoomsHeader searchSetting={searchSetting} setSearchSetting={setSearchSetting} /> },
                    { key: 'created', name: 'Oprettet', fieldName: 'created', minWidth: 70, maxWidth: 130, isResizable: true },
                    { key: 'details', name: 'Se mere', fieldName: 'details', minWidth: 110, maxWidth: 140, isResizable: true },
                ]}
                selectionMode={SelectionMode.none}
                enableShimmer={loading}
            />
        </Stack>
    )
}

export default CustomerList
