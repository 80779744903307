
type FormOptions = { id: string, name: string }
const sportsOptions: FormOptions[] = [
    { id: "fitness", name: "Fitness" },
    { id: "cycle", name: "Cykle" },
    { id: "walks", name: "Gåtur" },
    { id: "running", name: "Løbe" },
    { id: "sports", name: "Dyrke sportsgren" },
    { id: "golf", name: "Golf" },
]

const interestOptions: FormOptions[] = [
    { id: "gaming", name: "Gaming" },
    { id: "sports-watching", name: "Se sport" },
    { id: "cooking", name: "Madlavning" },
    { id: "friends", name: "Venner" },
    { id: "collector", name: "Samler" },
    { id: "gardening", name: "Haven" },
    { id: "drinks", name: "Vin/ Øl" },
    { id: "creative", name: "Kreativitet" },
]

const cultureOptions: FormOptions[] = [
    { id: "cafe", name: "Cafe" },
    { id: "cinema", name: "Biografen" },
    { id: "pub", name: "Pub" },
    { id: "restaurant", name: "Restaurant" },
]

const commuteOptions: FormOptions[] = [
    { id: "car", name: "Bil" },
    { id: "bike", name: "Cykel" },
    { id: "public-transport", name: "Offentlig transport" },
    { id: "home-office", name: "Arbejder hjemme" }
]

const petOptions: FormOptions[] = [
    { id: "dog", name: "Hund" },
    { id: "bird", name: "Fugle" },
    { id: "cat", name: "Kat" },
]

const familyOptions: FormOptions[] = [
    { id: "long-term-home", name: "Bolig til de næste 30 år" },
    { id: "family-increase", name: "Familieforøgelse" },
    { id: "temporary", name: "Midlertidigt" }
]

export const PropertySituationStatus = [
    { id: "rental", name: "Lejebolig" },
    { id: "estate", name: "Fast ejendom" },
    { id: "other", name: "Andet" },
]

const out = { familyOptions, petOptions, commuteOptions, cultureOptions, interestOptions, sportsOptions, PropertySituationStatus }
export default out