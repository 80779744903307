import * as React from 'react'
import hooks, { CVRApiItem } from '../utils/hooks'
import { useNavigate } from 'react-router-dom'
import { ChoiceGroup, Link, SearchBox, Stack, StackItem, TextField } from '@fluentui/react'
import { SmallGap } from '../utils/globals'
import { ErrorContext } from './wrappers';
import _ from 'lodash'
import { InputWithLabel, InputWrapper, isFieldInvalid, validateEmail } from './Fields'
import { ButtonWithLoading } from './Button'
import zipLib from '../utils/zipcode-lib'


const AreaPicker: React.FC<{ selectedAreas: string[], setSelectedAreas(areas: string[]): void }> = ({ selectedAreas, setSelectedAreas }) => {
    const [filterText, setFilterText] = React.useState<string>()
    const [suggestions, setSugetions] = React.useState<string[]>([])

    const onValueChange = (val?: string) => {
        if (!val || val.length === 0) {
            setSugetions([])
            setFilterText('')
            return
        }
        const local = zipLib.zipMap.filter((item) => item.name.toLocaleLowerCase().includes(val.toLocaleLowerCase())).map((i) => i.id)
        setSugetions(local.slice(0, 4))
        setFilterText(val)
    }

    return (
        <InputWrapper label='Områder' loading={false}>
            <Stack tokens={{ childrenGap: 6 }}>
                <TextField value={filterText} onChange={(_, val) => onValueChange(val)} />
                <ChoiceGroup options={suggestions.map(item => ({ key: item, text: zipLib.getZipDisplayName(item) }))}
                    onChange={(_, item) => item?.key ? setSelectedAreas([item.key]) : setSelectedAreas([])} />
            </Stack>
        </InputWrapper>
    )
}

const CvrAPI: React.FC<{ selectItem(item?: CVRApiItem): void }> = ({ selectItem }) => {
    const { showErrorMessage } = React.useContext(ErrorContext);

    const [term, setTerm] = React.useState<string>();
    const [items, setItems] = React.useState<CVRApiItem[] | undefined>([]);

    const handleSearch = _.debounce(async (input: string) => {
        setItems(undefined)
        selectItem(undefined)
        if (input && input.length === 0) { setItems(undefined); return }
        const result = await hooks.searchCVR(input);

        if (result) setItems(result);
        else showErrorMessage('Kan ikke tilslutte cvr apien');
    }, 300)

    React.useEffect(() => {
        if (term) handleSearch(term);
        // eslint-disable-next-line
    }, [term]);

    return (
        <Stack tokens={{ childrenGap: SmallGap }}>
            <StackItem><span>Søg i det centrale virksomhedsregister (CVR)</span></StackItem>
            <StackItem >
                <SearchBox placeholder="Søg her" onChange={(_, newValue) => setTerm(newValue)} />
                <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 12 }}>
                    {items && term && term.length > 0 && <ChoiceGroup options={items.map((i, key) => ({ text: i.name + " (DK " + i.vat + ")", key: key.toString() }))}
                        onChange={(_, item) => item?.key ? selectItem(items[Number(item.key)]) : selectItem(undefined)} />}
                </div>
                <Link onClick={() => selectItem()}>Indtast manuelt</Link>

            </StackItem>
        </Stack>
    )
}

const SignupAgencyComponent: React.FC = () => {
    const navigate = useNavigate()
    const { showErrorMessage } = React.useContext(ErrorContext)
    const [CVRHasBeenSelected, setCVRHasBeenSelected] = React.useState(false)

    const [email, setEmail] = React.useState<string>()

    const [legalName, setLegalName] = React.useState<string>()
    const [taxId, setTaxId] = React.useState<string>()
    const [phone, setPhone] = React.useState<string | undefined>("+45")
    const [address, setAddress] = React.useState<string>()
    const [zipcode, setZipcode] = React.useState<string>()
    const [city, setCity] = React.useState<string>()
    const [industrySec, setIndustrySec] = React.useState<string>()

    const [operatingAreas, setOperatingAreas] = React.useState<string[]>([])


    const phoneInvalid = !phone || phone.length<8
    const invalid = !validateEmail(email) || isFieldInvalid(legalName) || isFieldInvalid(address) || isFieldInvalid(zipcode) || isFieldInvalid(city) || isFieldInvalid(taxId) || isFieldInvalid(phone) || operatingAreas.length !== 1 || phoneInvalid

    const handleSubmissionEvent = async () => {
        if (!taxId) showErrorMessage("Venligst udfyld cvr nr")
        if (!phone || phone.length!==11) showErrorMessage("Venligst udfyld Telefonnummer")
        if (!validateEmail(email)) showErrorMessage("Venligst udfyld virksomhedens email")
        if (!legalName) showErrorMessage("Venligst udfyld virksomhedens legale navn")
        if (!address) showErrorMessage("Venligst udfyld virksomhedens adresse")
        if (!zipcode) showErrorMessage("Venligst udfyld virksomhedens postnummer")
        if (!city) showErrorMessage("Venligst udfyld virksomhedens by")
        if (!taxId || !email || !legalName || !zipcode || !city || !address || !validateEmail(email) || !phone) return
        const result = await hooks.createAgency({
            vat: taxId,
            name: legalName,
            address: address,
            zipcode: zipcode,
            city: city,
            email: email,
            industrydesc: industrySec || "ukendt",
            operatingAreas,
            phone
        })
        if (result) {
            navigate('/select-product')
        } else {
            showErrorMessage("Der skete en fejl")
        }
    }

    const handleCVRSubmissionEvent = (item?: CVRApiItem) => {
        setCVRHasBeenSelected(true)
        if (!item) return
        setLegalName(item.name)
        setAddress(item.address)
        setZipcode(item.zipcode.toString())
        setCity(item.city)
        setEmail(item.email)
        setIndustrySec(item.industrydesc)
        if(item.phone) setPhone("+45"+(item.phone))
        setTaxId(item.vat.toString())
    }


    return (
        <Stack tokens={{ childrenGap: SmallGap }}>
            <StackItem><h1>Opret dit kontor</h1></StackItem>
            <CvrAPI selectItem={handleCVRSubmissionEvent} />
            {CVRHasBeenSelected && <AreaPicker selectedAreas={operatingAreas} setSelectedAreas={setOperatingAreas} />}
            {CVRHasBeenSelected && <InputWithLabel setValue={setEmail} value={email} label='Virksomhedens email' />}
            {CVRHasBeenSelected && <InputWithLabel setValue={setLegalName} value={legalName} label='Virksomhedens legale navn' />}
            {CVRHasBeenSelected && <InputWithLabel setValue={setTaxId} value={taxId} label='CVR Nr' />}
            {CVRHasBeenSelected && <InputWithLabel setValue={setPhone} value={phone} label='Telefonnummer' />}
            {CVRHasBeenSelected && <InputWithLabel setValue={setAddress} value={address} label='Virksomhedens adresse' />}
            <Stack horizontal tokens={{ childrenGap: SmallGap }}>
                {CVRHasBeenSelected && <InputWithLabel setValue={setZipcode} value={zipcode} label='Postnummer' />}
                {CVRHasBeenSelected && <InputWithLabel setValue={setCity} value={city} label='By' />}
            </Stack>
            <StackItem> <ButtonWithLoading text="Næste" onClick={handleSubmissionEvent} disabled={invalid || !email} /></StackItem>
        </Stack>
    )
}

export default SignupAgencyComponent