import * as React from 'react'
import { ChoiceGroup, SearchBox, Stack, StackItem } from '@fluentui/react'
import { SmallGap } from '../utils/globals'
import { ErrorContext } from './wrappers';
import { AgencyLookupData } from '@venando/common';
import hooks from '../utils/hooks';
import _ from 'lodash';
import { ButtonWithLoading } from './Button';

const BusinessLookup: React.FC<{ selectItem(item: string): void }> = ({ selectItem }) => {
    const { showErrorMessage } = React.useContext(ErrorContext);
    const [loading, setLoading] = React.useState(false)
    const [term, setTerm] = React.useState<string>();
    const [items, setItems] = React.useState<AgencyLookupData[]>();

    const handleSearch = _.debounce(async (input: string) => {
        if (input && input.length === 0) { setItems(undefined); return }
        setLoading(true)
        const result = await hooks.searchAgency(input);

        if (result) setItems(result);
        else showErrorMessage('Der skete en fejl');
        setLoading(false)
    }, 300)


    React.useEffect(() => {
        if (term) handleSearch(term);
        // eslint-disable-next-line
    }, [term]);

    return (
        <Stack tokens={{ childrenGap: SmallGap }}>
            <StackItem><span>Søg i virksomheder som allerede er på venando</span></StackItem>
            <StackItem >
                <SearchBox placeholder="Søg her" onChange={(_, newValue) => setTerm(newValue)} />
                {items && term && term.length > 0 && <ChoiceGroup options={items.map((i) => ({ key: i.agencyId, text: i.internalDisplayName }))} onChange={(_, item) => item && selectItem(item?.key)} />}
            </StackItem>
            <StackItem><span>{!loading && items && items.length === 0 && term && term.length > 0 && <span>Ingen resulater</span>}</span></StackItem>
        </Stack>
    )
}

const RequestAccessToCompanyComponent: React.FC = () => {
    const { showErrorMessage } = React.useContext(ErrorContext)

    const [agencyId, setAgencyId] = React.useState<string>()
    const [success, setSuccess] = React.useState<boolean>()

    const handleSubmissionEvent = async () => {
        if (!agencyId) return
        const result = await hooks.requestAgencyAccess(agencyId)
        if (result) {
            setSuccess(true)
        } else {
            showErrorMessage("Der skete en fejl")
        }
    }

    if (success) return (
        <Stack tokens={{ childrenGap: SmallGap }}>
            <StackItem><p>Tak for din anmodning. Du vil modtage en email når anmodningen er blevet godkendt.</p></StackItem>
        </Stack>
    )


    return (
        <Stack tokens={{ childrenGap: SmallGap }}>
            <StackItem><h1>Anmod om adgang</h1></StackItem>
            <BusinessLookup selectItem={setAgencyId} />
            <StackItem> <ButtonWithLoading text="Anmod om adgang" onClick={handleSubmissionEvent} disabled={false || !agencyId} /></StackItem>
        </Stack>
    )
}

export default RequestAccessToCompanyComponent